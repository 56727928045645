import React from 'react'

const Topheader = () => {
  return (
    <div> <div class="container-fluid bg-primary py-3">
    <div class="container">
        <div class="row">
            <div class="col-md-6 text-center text-lg-left mb-2 mb-lg-0">
                <div class="d-inline-flex align-items-center">
                    <a class="text-white pr-3" href="/contact">FAQs</a>
                    <span class="text-white">|</span>
                    <a class="text-white px-3" href="/contact">Help</a>
                    <span class="text-white">|</span>
                    <a class="text-white pl-3" href="/contact">Support</a>
                </div>
            </div>
            <div class="col-md-6 text-center text-lg-right">
                <div class="d-inline-flex align-items-center">
                    
                     <a class="text-white px-3" href="">
                        <i class="fa fa-phone-alt mr-2"> +91 9980893560 / 9886452359</i>
                    </a>
                    <a class="text-white px-3" href="mailto:psin2010@gmail.com">
    <i class="fa fa-envelope mr-2"></i> psin2010@gmail.com
</a>
                 {/*   <a class="text-white px-3" href="">
                        <i class="fab fa-linkedin-in"></i>
                    </a>
                    <a class="text-white px-3" href="">
                        <i class="fab fa-instagram"></i>
                    </a>
                    <a class="text-white pl-3" href="">
                        <i class="fab fa-youtube"></i>
                    </a> */}
                </div>
            </div>
        </div>
    </div>
</div></div>
  )
}

export default Topheader