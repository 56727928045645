import React,{useEffect,useState} from 'react';
import Slider from '../Components/Slider';
import { GiCharcuterie } from "react-icons/gi";

import { GiCircularSawblade } from "react-icons/gi";
import { GiDrill } from "react-icons/gi";
import { GiPizzaCutter } from "react-icons/gi";
import Testinomial from './Testinomial';

const Home = () => {
    const [isMobile, setIsMobile] = useState(false);

    // Check screen size for mobile view
    useEffect(() => {
      const checkMobileView = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      window.addEventListener('resize', checkMobileView);
  
      // Initial check on component mount
      checkMobileView();
  
      return () => window.removeEventListener('resize', checkMobileView);
    }, []);
  
    const textStyle = {
      textAlign: 'justify',
      lineHeight: '1.6',
      marginBottom: '15px',
      fontFamily: 'Arial, sans-serif',
      fontSize: isMobile ? '14px' : '16px', // Adjust font size for mobile
      padding: isMobile ? '10px' : '10px',  // Adjust padding for mobile
    };
  
    
  
  return (
    <div>
    
     <Slider />
    <div class="container-fluid contact-info mt-5 mb-4">
        <div class="container" style={{padding: "0 30px"}}>
            <div class="row">
                <div class="col-md-4 d-flex align-items-center justify-content-center bg-secondary mb-4 mb-lg-0" style={{height: "100px"}}>
                    <div class="d-inline-flex">
                        <i class="fa fa-2x fa-map-marker-alt text-white m-0 mr-3"></i>
                        <div class="d-flex flex-column">
                            <h5 class="text-white font-weight-medium">Our Location</h5>
                            <p class="m-0 text-white">#C/90/A, KSSIDC DIEA Mahadevapura,Bangalore-560048</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 d-flex align-items-center justify-content-center bg-secondary mb-4 mb-lg-0" style={{height: "100px"}}>
                    <div class="d-inline-flex text-left">
                        <i class="fa fa-2x fa-envelope text-white m-0 mr-3"></i>
                        <div class="d-flex flex-column">
                            <h5 class="text-white font-weight-medium">Email Us</h5>
                            <p class="m-0 text-white">psin2010@gmail.com</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 d-flex align-items-center justify-content-center bg-secondary mb-4 mb-lg-0" style={{height: "100px"}}>
                    <div class="d-inline-flex text-left">
                        <i class="fa fa-2x fa-phone-alt text-white m-0 mr-3"></i>
                        <div class="d-flex flex-column">
                            <h5 class="text-white font-weight-medium">Call Us</h5>
                            <p class="m-0 text-white">+91 9945876320 / 9886452359</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   


  
    <div class="container-fluid py-5">
        <div class="container pt-0 pt-lg-4">
            <div class="row align-items-center">
                <div class="col-lg-5" data-aos="fade-down-right" data-aos-duration="3000">
                    <img class="img-fluid" src="img/abt3.jpg" alt="" style={{height:"550px",width:"600px"}}/>
                </div>
                <div class="col-lg-7 mt-5 mt-lg-0 pl-lg-5" data-aos="fade-down-left" data-aos-duration="3000">
                    <h6 class="text-secondary text-uppercase font-weight-medium mb-3">Learn About Us</h6>
                    <h1 class="mb-4">Welcome to P&S Industries</h1>
                    <h5 class="font-weight-medium font-italic mb-4">We Are Your Trusted Mechanical Solutions Provider</h5>
                  
      <p style={textStyle}>
        P&S Industries is a proprietorship firm founded by Mrs. Nalina C. in 2007. Located at #C/90/A, KSSIDC Industrial Estate, Dyavasandra, Mahadevapura, Bengaluru-560048, Karnataka, India, we are proud to be an ISO 9001:2008 certified company. With years of industry experience, we pride ourselves on our ability to tackle any mechanical challenge. Our team of skilled professionals is dedicated to providing innovative solutions that meet your needs.
      </p> 
                    <div class="row">
                        <div class="col-sm-6 pt-3">
                            <div class="d-flex align-items-center">
                                <i class="fa fa-check text-primary mr-2"></i>
                                <p class="text-secondary font-weight-medium m-0">Comprehensive Mechanical Repairs</p>
                            </div>
                        </div>
                        <div class="col-sm-6 pt-3">
                            <div class="d-flex align-items-center">
                                <i class="fa fa-check text-primary mr-2"></i>
                                <p class="text-secondary font-weight-medium m-0">Fast and Efficient Service</p>
                            </div>
                        </div>
                        <div class="col-sm-6 pt-3">
                            <div class="d-flex align-items-center">
                                <i class="fa fa-check text-primary mr-2"></i>
                                <p class="text-secondary font-weight-medium m-0">Highly Skilled Technicians</p>
                            </div>
                        </div>
                        <div class="col-sm-6 pt-3">
                            <div class="d-flex align-items-center">
                                <i class="fa fa-check text-primary mr-2"></i>
                                <p class="text-secondary font-weight-medium m-0">100% Customer Satisfaction </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    


   
    <div class="container-fluid pt-5 pb-3">
        <div class="container">
            <h6 class="text-secondary text-uppercase text-center font-weight-medium mb-3">Our Services</h6>
            <h1 class="display-4 text-center mb-5">What We Offer</h1>
            <div class="row">
                <div class="col-lg-3 col-md-6 pb-1" data-aos="flip-left" data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center bg-light mb-4 px-4" style={{height: "300px"}}>
                        <div class="d-inline-flex align-items-center justify-content-center bg-white shadow rounded-circle mb-4" style={{width: "100px" , height: "100px"}}>
                        <GiCharcuterie style={{fontSize:"80px",color:"orange"}}/>
                        </div>
                        <h4 class="font-weight-bold m-0">Rough Milling</h4>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 pb-1" data-aos="flip-left" data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center bg-light mb-4 px-4" style={{height: "300px"}}>
                        <div class="d-inline-flex align-items-center justify-content-center bg-white shadow rounded-circle mb-4" style={{width: "100px" ,height: "100px"}}>
                        < GiDrill style={{fontSize:"70px",color:"orange"}}/>

                        </div>
                        <h4 class="font-weight-bold m-0"> Surface Grinding</h4>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 pb-1" data-aos="flip-left" data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center bg-light mb-4 px-4" style={{height: "300px"}}>

<div class="d-inline-flex align-items-center justify-content-center bg-white shadow rounded-circle mb-4" style={{width: "100px", height: "100px"}}>
                        <GiPizzaCutter style={{fontSize:"70px",color:"orange"}}/>

                        </div>
                        <h4 class="font-weight-bold m-0">Jig Boring</h4>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 pb-1" data-aos="flip-left" data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center bg-light mb-4 px-4" style={{height: "300px"}}>
                        <div class="d-inline-flex align-items-center justify-content-center bg-white shadow rounded-circle mb-4" style={{width: "100px", height: "100px"}}>
                            <GiCircularSawblade style={{fontSize:"70px",color:"orange"}}/>
                        </div>
                        <h4 class="font-weight-bold m-0">VMC (CNC) Precision Milling</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
   


   
    <div class="container-fluid py-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 m-0 my-lg-5 pt-0 pt-lg-5 pr-lg-5">
                    <h6 class="text-secondary text-uppercase font-weight-medium mb-3">Our Features</h6>
                    <h1 class="mb-4">Why Choose Us</h1>
                    

<p style={textStyle}>
At P&S Industries, we are committed to quality and precision. Our experienced team and state-of-the-art machinery enable us to meet the diverse needs of our clients, ensuring complete satisfaction with every project.

Let us be your trusted partner in all your mechanical needs!
      </p>
                    <div class="row">
                        <div class="col-sm-6 mb-4">
                            <h1 class="text-secondary" data-toggle="counter-up">17</h1>
                            <h5 class="font-weight-bold">Years Expereince</h5>
                        </div>
                        <div class="col-sm-6 mb-4">
                            <h1 class="text-secondary" data-toggle="counter-up">150</h1>
                            <h5 class="font-weight-bold">Expert Worker</h5>
                        </div>
                        <div class="col-sm-6 mb-4">
                            <h1 class="text-secondary" data-toggle="counter-up">760</h1>
                            <h5 class="font-weight-bold">Happy Clients</h5>
                        </div>
                        <div class="col-sm-6 mb-4">
                            <h1 class="text-secondary" data-toggle="counter-up">1567</h1>
                            <h5 class="font-weight-bold">Jig Boring</h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5" div data-aos="zoom-in-left" data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center bg-secondary h-100 py-5 px-3">
                        <i class="fa fa-5x fa-certificate text-white mb-5"></i>
                        <h1 class="display-1 text-white mb-3">17+</h1>
                        <h1 class="text-white m-0">Years Experience</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
   


    
    <div class="container-fluid pt-5">
        <div class="container">
            <h6 class="text-secondary text-uppercase text-center font-weight-medium mb-3">Working Process</h6>
            <h1 class="display-4 text-center mb-5">How We Work</h1>
            <div class="row">
                <div class="col-lg-3 col-md-6"  data-aos="zoom-out"  data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center mb-5">
                        <div class="d-inline-flex align-items-center justify-content-center bg-white border border-light shadow rounded-circle mb-4" style={{width: "150px", height: "150px", borderWidth: "15px !important"}}>
                            <h2 class="display-2 text-secondary m-0">1</h2>
                        </div>
                        <h3 class="font-weight-bold m-0 mt-2">Order Placement</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6"  data-aos="zoom-out"  data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center mb-5">
                        <div class="d-inline-flex align-items-center justify-content-center bg-white border border-light shadow rounded-circle mb-4" style={{width: "150px", height: "150px", borderWidth: "15px !important"}}>
                            <h2 class="display-2 text-secondary m-0">2</h2>
                        </div>
                        <h3 class="font-weight-bold m-0 mt-2">Precision Machining</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6"  data-aos="zoom-out"  data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center mb-5">
                        <div class="d-inline-flex align-items-center justify-content-center bg-white border border-light shadow rounded-circle mb-4" style={{width: "150px", height: "150px", borderWidth: "15px !important"}}>
                            <h2 class="display-2 text-secondary m-0">3</h2>
                        </div>
                        <h3 class="font-weight-bold m-0 mt-2">Quality Assurance</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6"  data-aos="zoom-out"  data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center mb-5">
                        <div class="d-inline-flex align-items-center justify-content-center bg-white border border-light shadow rounded-circle mb-4" style={{width: "150px", height: "150px", borderWidth: "15px !important"}}>
                            <h2 class="display-2 text-secondary m-0">4</h2>
                        </div>
                        <h3 class="font-weight-bold m-0 mt-2">Free Delivery</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    


   
    {/* <div class="container-fluid pt-5 pb-3">
        <div class="container">
            <h6 class="text-secondary text-uppercase text-center font-weight-medium mb-3">Our Pricing Plan</h6>
            <h1 class="display-4 text-center mb-5">The Best Price</h1>
            <div class="row">
                <div class="col-lg-4 mb-4">
                    <div class="bg-light text-center mb-2 pt-4">
                        <div class="d-inline-flex flex-column align-items-center justify-content-center bg-secondary rounded-circle shadow mt-2 mb-4" style={{width: "200px", height: "200px", border: "15px solid #ffffff"}}>
                            <h3 class="text-white">Basic</h3>
                            <h1 class="display-4 text-white mb-0">
                                <small class="align-top" style={{fontSize: "22px", lineHeight: "45px"}}>$</small>49<small class="align-bottom" style={{fontSize: "16px", lineHeight: "40px"}}>/ Mo</small>
                            </h1>
                        </div>
                        <div class="d-flex flex-column align-items-center py-3">
                            <p>HTML5 & CSS3</p>
                            <p>Bootstrap 4</p>
                            <p>Responsive Layout</p>
                            <p>Compatible With All Browsers</p>
                        </div>
                        <a href="" class="btn btn-secondary py-2 px-4">Signup Now</a>
                    </div>
                </div>
                <div class="col-lg-4 mb-4">
                    <div class="bg-light text-center mb-2 pt-4">
                        <div class="d-inline-flex flex-column align-items-center justify-content-center bg-primary rounded-circle shadow mt-2 mb-4" style={{width: "200px", height: "200px", border: "15px solid #ffffff"}}>
                            <h3 class="text-white">Standard</h3>
                            <h1 class="display-4 text-white mb-0">
                                <small class="align-top" style={{fontSize: "22px", lineHeight: "45px"}}>$</small>99<small class="align-bottom" style={{fontSize: "16px", lineHeight: "40px"}}>/ Mo</small>
                            </h1>
                        </div>
                        <div class="d-flex flex-column align-items-center py-3">
                            <p>HTML5 & CSS3</p>
                            <p>Bootstrap 4</p>
                            <p>Responsive Layout</p>
                            <p>Compatible With All Browsers</p>
                        </div>
                        <a href="" class="btn btn-primary py-2 px-4">Signup Now</a>
                    </div>
                </div>
                <div class="col-lg-4 mb-4">
                    <div class="bg-light text-center mb-2 pt-4">
                        <div class="d-inline-flex flex-column align-items-center justify-content-center bg-secondary rounded-circle shadow mt-2 mb-4" style={{width: "200px", height: "200px" ,border: "15px solid #ffffff"}}>
                            <h3 class="text-white">Premium</h3>
                            <h1 class="display-4 text-white mb-0">
                                <small class="align-top" style={{fontSize: "22px", lineHeight: "45px"}}>$</small>149<small class="align-bottom" style={{fontSize: "16px", lineHeight: "40px"}}>/ Mo</small>
                            </h1>
                        </div>
                        <div class="d-flex flex-column align-items-center py-3">
                            <p>HTML5 & CSS3</p>
                            <p>Bootstrap 4</p>
                            <p>Responsive Layout</p>
                            <p>Compatible With All Browsers</p>
                        </div>
                        <a href="" class="btn btn-secondary py-2 px-4">Signup Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
   
<Testinomial/>

    {/* <div class="container-fluid py-5">
        <div class="container">
            <h6 class="text-secondary text-uppercase text-center font-weight-medium mb-3">Testimonial</h6>
            <h1 class="display-4 text-center mb-5">Our Clients Say</h1>
            <div class="owl-carousel testimonial-carousel">
                <div class="testimonial-item">
                    <img class="position-relative rounded-circle bg-white shadow mx-auto" src="img/testimonial-1.jpg" style={{width: "100px", height: "100px", padding: "12px", marginBottom: "-50px", zIndex:"1"}}/>
                    <div class="bg-light text-center p-4 pt-0">
                        <h5 class="font-weight-medium mt-5">Client Name</h5>
                        <p class="text-muted font-italic">Profession</p>
                        <p class="m-0">Sed ea amet kasd elitr stet, stet rebum et ipsum est duo elitr eirmod clita lorem. Dolor ipsum clita</p>
                    </div>
                </div>
                <div class="testimonial-item">
                    <img class="position-relative rounded-circle bg-white shadow mx-auto" src="img/testimonial-2.jpg" style={{width: "100px", height:"100px", padding:" 12px", marginBottom: "-50px", zIndex: "1"}}/>
                    <div class="bg-light text-center p-4 pt-0">
                        <h5 class="font-weight-medium mt-5">Client Name</h5>
                        <p class="text-muted font-italic">Profession</p>
                        <p class="m-0">Sed ea amet kasd elitr stet, stet rebum et ipsum est duo elitr eirmod clita lorem. Dolor ipsum clita</p>
                    </div>
                </div>
                <div class="testimonial-item">
                    <img class="position-relative rounded-circle bg-white shadow mx-auto" src="img/testimonial-3.jpg" style={{width: "100px", height: "100px", padding: "12px", marginBottom: "-50px", zIndex: "1" }}/>
                    <div class="bg-light text-center p-4 pt-0">
                        <h5 class="font-weight-medium mt-5">Client Name</h5>
                        <p class="text-muted font-italic">Profession</p>
                        <p class="m-0">Sed ea amet kasd elitr stet, stet rebum et ipsum est duo elitr eirmod clita lorem. Dolor ipsum clita</p>
                    </div>
                </div>
                <div class="testimonial-item">
                    <img class="position-relative rounded-circle bg-white shadow mx-auto" src="img/testimonial-4.jpg" style={{width: "100px", height: "100px", padding: "12px", marginBottom: "-50px", zIndex: "1"}} alt=""/>
                    <div class="bg-light text-center p-4 pt-0">
                        <h5 class="font-weight-medium mt-5">Client Name</h5>
                        <p class="text-muted font-italic">Profession</p>
                        <p class="m-0">Sed ea amet kasd elitr stet, stet rebum et ipsum est duo elitr eirmod clita lorem. Dolor ipsum clita</p>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
  


   
   {/* <div class="container-fluid mt-5 pb-2">
        <div class="container">
            <h6 class="text-secondary text-uppercase text-center font-weight-medium mb-3">Our Blog</h6>
            <h1 class="display-4 text-center mb-5">Latest From Blog</h1>
            <div class="row">
                <div class="col-lg-4 mb-2">
                    <div class="shadow mb-4">
                        <div class="position-relative">
                            <img class="img-fluid w-100" src="img/blog-1.jpg" alt=""/>
                            <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                                <h4 class="text-center text-white font-weight-medium mb-3">Dolor site amet clita kasd sanct ipsum</h4>
                                <div class="d-flex text-light">
                                    <small class="mr-2"><i class="fa fa-user text-secondary"></i> Admin</small>
                                    <small class="mr-2"><i class="fa fa-folder text-secondary"></i> Web Design</small>
                                    <small class="mr-2"><i class="fa fa-comments text-secondary"></i> 15</small>
                                </div>
                            </a>
                        </div>
                        <p class="m-0 p-4">Amet dolores labore magna et amet tempor dolor et dolor. Et sit ipsum et eos rebum labore ea labore sea. Et sed elitr labore sed labore. Lorem et lorem amet sed sed kasd ipsum rebum</p>
                    </div>
                </div>
                <div class="col-lg-4 mb-2">
                    <div class="shadow mb-4">
                        <div class="position-relative">
                            <img class="img-fluid w-100" src="img/blog-2.jpg" alt=""/>
                            <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                                <h4 class="text-center text-white font-weight-medium mb-3">Dolor site amet clita kasd sanct ipsum</h4>
                                <div class="d-flex text-light">
                                    <small class="mr-2"><i class="fa fa-user text-secondary"></i> Admin</small>
                                    <small class="mr-2"><i class="fa fa-folder text-secondary"></i> Web Design</small>
                                    <small class="mr-2"><i class="fa fa-comments text-secondary"></i> 15</small>
                                </div>
                            </a>
                        </div>
                        <p class="m-0 p-4">Amet dolores labore magna et amet tempor dolor et dolor. Et sit ipsum et eos rebum labore ea labore sea. Et sed elitr labore sed labore. Lorem et lorem amet sed sed kasd ipsum rebum</p>
                    </div>
                </div>
                <div class="col-lg-4 mb-2">
                    <div class="shadow mb-4">
                        <div class="position-relative">
                            <img class="img-fluid w-100" src="img/blog-3.jpg" alt=""/>
                            <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                                <h4 class="text-center text-white font-weight-medium mb-3">Dolor site amet clita kasd sanct ipsum</h4>
                                <div class="d-flex text-light">
                                    <small class="mr-2"><i class="fa fa-user text-secondary"></i> Admin</small>
                                    <small class="mr-2"><i class="fa fa-folder text-secondary"></i> Web Design</small>
                                    <small class="mr-2"><i class="fa fa-comments text-secondary"></i> 15</small>
                                </div>
                            </a>
                        </div>
                        <p class="m-0 p-4">Amet dolores labore magna et amet tempor dolor et dolor. Et sit ipsum et eos rebum labore ea labore sea. Et sed elitr labore sed labore. Lorem et lorem amet sed sed kasd ipsum rebum</p>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
     
    </div>
  )
}

export default Home