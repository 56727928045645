import React from 'react'
import './Footer.css';

const Footer = () => {
  return (
    <div>
         <div className="container-fluid bg-primary text-white mt-5 pt-5 px-sm-3 px-md-5 footer-container">
      <div className="row pt-5">
        {/* Logo and Description */}
        <div className="col-lg-3 col-md-6 mb-5">
          <img
            src="img/logo3.png"
            alt=""
            className="footer-logo"
          />
          <a href="">
            <h1 className="text-secondary mb-3">
              <span className="text-white">P&S</span> Industries
            </h1>
          </a>
          <p>
            At P&S Industries, we recognize the critical importance of a highly skilled workforce in advancing the manufacturing and machining sectors.
          </p>
        </div>

        {/* Get In Touch */}
        <div className="col-lg-3 col-md-6 mb-5">
          <h4 className="text-white mb-4">Get In Touch</h4>
          <p>#B41/A, KSSIDC Industrial Estate, Devasandra</p>
          <p>
            <i className="fa fa-map-marker-alt mr-2"></i>Mahadevapura, Bangalore-560048
          </p>
          <p>
            <i className="fa fa-phone-alt mr-2"></i>+91 9980893560
          </p>
          <p>
            <i className="fa fa-envelope mr-2"></i>
            <a href="mailto:psin2010@gmail.com" className="text-white">
              psin2010@gmail.com
            </a>
          </p>
        </div>

        {/* Quick Links */}
        <div className="col-lg-3 col-md-6 mb-5">
          <h4 className="text-white mb-4">Quick Links</h4>
          <div className="d-flex flex-column justify-content-start">
            <a className="text-white mb-2" href="/">
              <i className="fa fa-angle-right mr-2"></i>Home
            </a>
            <a className="text-white mb-2" href="/about">
              <i className="fa fa-angle-right mr-2"></i>About Us
            </a>
            <a className="text-white mb-2" href="/service">
              <i className="fa fa-angle-right mr-2"></i>Services
            </a>
            <a className="text-white mb-2" href="/product">
              <i className="fa fa-angle-right mr-2"></i>Products
            </a>
            <a className="text-white" href="/contact">
              <i className="fa fa-angle-right mr-2"></i>Contact Us
            </a>
          </div>
        </div>

        {/* Google Map Embed */}
        <div className="col-lg-3 col-md-6 mb-5">
          <h4 className="text-white mb-4">Our Location</h4>
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.5989422203074!2d77.69507327507685!3d12.997484087320267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae110af5e75b09%3A0x851ebdcef303df7a!2sP%26S%20Industries!5e0!3m2!1sen!2sin!4v1727498946947!5m2!1sen!2sin"
              width="100%"
              height="50%"
              style={{ border: '0' }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Map"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid bg-dark text-white py-4 px-sm-3 px-md-5">
  <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
    <span class="text-body mb-2 mb-md-0" style={{fontSize: "15px"}}>
      <a href="#" class="text-white font-weight-medium" >
        © 2024 P&S Industries
      </a>, All Rights Reserved.
    </span>
    <p class="m-0 text-center text-md-right">
      <span class="d-block d-md-inline">Designed By</span> 
      <a class="text-white font-weight-medium" href="https://www.excerptech.com/"> Excerpt Technologies Pvt Ltd</a>
    </p>
  </div>
</div>

    </div>


  )
}

export default Footer