import React, { useEffect,useState } from 'react'
import { GiCircularSawblade } from "react-icons/gi";
import { GiDrill } from "react-icons/gi";
import { GiPizzaCutter } from "react-icons/gi";
import { GiCharcuterie } from "react-icons/gi";
import AOS from 'aos';  
import MeasurementToolsTable from './MeasurementToolsTable';
import 'aos/dist/aos.css'; // Ensure to import AOS CSS
import './Service.css';



const Service = () => {
    const [modalImage, setModalImage] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const headingStyle = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        
        fontSize: isMobile ? '1.5rem' : '2.4rem', 
        
    };
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    

        AOS.init();
      }, []);

    const handleImageClick = (imageSrc) => {
        setModalImage(imageSrc);
    };

    const closeModal = () => {
        setModalImage(null);
    };
  return (
    <div>
        
    
    <div class="page-header container-fluid bg-secondary pt-2 pt-lg-5 pb-2 mb-5">
    <div className="container py-5">
            <div className="row align-items-center py-4">
                <div className="col-md-6 text-center text-md-left">
                    <h1 style={headingStyle} className="mb-0 text-white">Our Services and Machinery</h1>
                </div>
                <div className="col-md-6 text-center text-md-right">
                    <div className="d-inline-flex align-items-center">
                        <a className="btn text-white" href="/">Home</a>
                        <i className="fas fa-angle-right text-white"></i>
                        <a className="btn text-white disabled" href="">Services</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
   
  
    <div class="container-fluid pt-5 pb-3">
        <div class="container">
            <h6 class="text-secondary text-uppercase text-center font-weight-medium mb-3">Our Services </h6>
            <h1 class="display-4 text-center mb-5">What We Offer</h1>
            <div class="row">
                <div class="col-lg-3 col-md-6 pb-1" data-aos="flip-left" data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center bg-light mb-4 px-4" style={{height: "300px"}}>
                        <div class="d-inline-flex align-items-center justify-content-center bg-white shadow rounded-circle mb-4" style={{width: "100px", height: "100px"}}>
                        < GiDrill style={{fontSize:"70px",color:"orange"}}/>
                        </div>
                        <h4 class="font-weight-bold m-0">Rough Milling</h4>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 pb-1" data-aos="flip-left" data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center bg-light mb-4 px-4" style={{height: "300px"}}>
                        <div class="d-inline-flex align-items-center justify-content-center bg-white shadow rounded-circle mb-4" style={{width: "100px", height: "100px"}}>
                        <GiCircularSawblade style={{fontSize:"70px",color:"orange"}}/>
                        </div>
                        <h4 class="font-weight-bold m-0">Surface Grinding</h4>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 pb-1" data-aos="flip-left" data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center bg-light mb-4 px-4" style={{height: "300px"}}>
                        <div class="d-inline-flex align-items-center justify-content-center bg-white shadow rounded-circle mb-4" style={{width: "100px", height: "100px"}}>
                        <GiCharcuterie style={{fontSize:"80px",color:"orange"}}/>
                        </div>
                        <h4 class="font-weight-bold m-0">Jig Boring</h4>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 pb-1" data-aos="flip-left" data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center bg-light mb-4 px-4" style={{height: "300px"}}>
                        <div class="d-inline-flex align-items-center justify-content-center bg-white shadow rounded-circle mb-4" style={{width: "100px", height: "100px"}}>
                        <GiPizzaCutter style={{fontSize:"70px",color:"orange"}}/>
                        </div>
                        <h4 class="font-weight-bold m-0"> VMC (CNC) Precision Milling</h4>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 pb-1" data-aos="flip-left" data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center bg-light mb-4 px-4" style={{height: "300px"}}>
                        <div class="d-inline-flex align-items-center justify-content-center bg-white shadow rounded-circle mb-4" style={{width: "100px", height: "100px"}}>
                        <img class="img-fluid" src="img/spark.png" alt=""  style={{height:"60px",color:"orange"}} />
                        </div>
                        <h4 class="font-weight-bold m-0">Threading </h4>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 pb-1"data-aos="flip-left" data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center bg-light mb-4 px-4" style={{height: "300px"}}>
                        <div class="d-inline-flex align-items-center justify-content-center bg-white shadow rounded-circle mb-4"style={{width: "100px", height: "100px"}}>
                        <img class="img-fluid" src="img/spark1.png" alt=""  style={{height:"60px",color:"orange"}} />
                        </div>
                        <h4 class="font-weight-bold m-0"> Sparking</h4>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 pb-1"data-aos="flip-left" data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center bg-light mb-4 px-4" style={{height: "300px"}}>
                        <div class="d-inline-flex align-items-center justify-content-center bg-white shadow rounded-circle mb-4" style={{width: "100px", height: "100px"}}>
                        <img class="img-fluid" src="img/turn.png" alt=""  style={{height:"60px",color:"orange"}} />
                        </div>
                        <h4 class="font-weight-bold m-0"> Turning </h4>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 pb-1"data-aos="flip-left" data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center bg-light mb-4 px-4" style={{height: "300px"}}>
                        <div class="d-inline-flex align-items-center justify-content-center bg-white shadow rounded-circle mb-4" style={{width: "100px", height: "100px"}}>
                        <img class="img-fluid" src="img/dril.png" alt=""  style={{height:"60px",color:"orange"}} />
                        </div>
                        <h4 class="font-weight-bold m-0"> Drilling</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
    
    {/* <div class="container-fluid pt-5 pb-3">
    <div class="container">
       
        <h1 class="display-4 text-center mb-5">Design and Development </h1>
        <h5 class="text-secondary  text-center font-weight-medium mb-3" style={{textAlign:'justify'}}>We specialize in offering comprehensive design and programming solutions for machining operations in VMC (Vertical Machining Center) machines, customized to meet each client's unique requirements. Our expert team ensures precision in every project, delivering high-quality, efficient machining processes. By understanding the specific needs of our customers, we provide tailored solutions that enhance performance and productivity. Whether it's a one-off job or ongoing support, our services are designed to deliver reliability and precision in every machining task.</h5>
        <div class="container-fluid py-5">
            <div class="container pt-0 pt-lg-4">
           
                <div class="row justify-content-center align-items-center">
                    <div class="col-lg-5 text-center" data-aos="fade-right" data-aos-duration="3000">
                        <img class="img-fluid" src="img/d1.jpg" alt="" style={{height:"350px",width:"800px"}} />
                    </div>
                    <div class="col-lg-5 text-center" data-aos="fade-left" data-aos-duration="3000">
                        <img class="img-fluid" src="img/d2.jpg" alt="" style={{height:"350px",width:"800px"}}  />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

  


    <div class="container-fluid mt-5 pb-2">
        <div class="container">
            <h6 class="text-secondary text-uppercase text-center font-weight-medium mb-3">Our Machinery</h6>
            <h1 class="display-4 text-center mb-5">Latest Machinery</h1>
            <h4 class="text-secondary text-uppercase text-center font-weight-medium mb-3">1. DMC 1035V ecoline  –  VMC Machine
            (Vertical Milling Machine)
            <h6 class="text-secondary text-uppercase text-center font-weight-medium mb-3">Make: DMG MORI -1100 x 600 x 600mm
            </h6>
            </h4>
            <div class="row">
                <div class="col-lg-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
                    <div class="shadow mb-4">
                        <div class="position-relative">
                        <img class="img-fluid w-100" src="img/v1.jpg" alt="" style={{height:'250px'}}/>
                            <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                            
                            </a>
                        </div>
                       
                    </div>
                </div>
                <div class="col-lg-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
                    <div class="shadow mb-4">
                        <div class="position-relative">
                            <img class="img-fluid w-100" src="img/v2.jpg" alt="" style={{height:'250px'}}/>
                            <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                                
                            </a>
                        </div>
                      
                    </div>
                </div>
                <div class="col-lg-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
                    <div class="shadow mb-4">
                        <div class="position-relative">
                        <img class="img-fluid w-100" src="img/v3.jpg" alt="" style={{height:'250px'}}/>
                            <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                              
                            </a>
                        </div>
                       
                    </div>
                </div>
            </div>

           
        </div>
        <div class="container">
           
            <h4 class="text-secondary text-uppercase text-center font-weight-medium mb-3">2. HAAS MACHINE – VMC MACHINE

            <h6 class="text-secondary text-uppercase text-center font-weight-medium mb-3">Make: VF – 2YT – 760 x 510 x 510mm

            </h6>
            </h4>
            <div class="row">
                <div class="col-lg-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
                    <div class="shadow mb-4">
                        <div class="position-relative">
                        <img class="img-fluid w-100" src="img/v4.jpg" alt="" style={{height:'250px'}}/>
                            <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                                
                            </a>
                        </div>
                       
                    </div>
                </div>
                <div class="col-lg-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
                    <div class="shadow mb-4">
                        <div class="position-relative">
                            <img class="img-fluid w-100" src="img/v5.jpg" alt="" style={{height:'250px'}}/>
                            <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                                
                            </a>
                        </div>
                      
                    </div>
                </div>
                <div class="col-lg-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
                    <div class="shadow mb-4">
                        <div class="position-relative">
                        <img class="img-fluid w-100" src="img/v6.jpg" alt="" style={{height:'250px'}}/>
                            <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                                
                            </a>
                        </div>
                       
                    </div>
                </div>
            </div>

           
        </div>
        <div class="container">
           
            <h4 class="text-secondary text-uppercase text-center font-weight-medium mb-3">3. HAAS MACHINE – VMC MACHINE

            (Vertical Milling Machine)
            <h6 class="text-secondary text-uppercase text-center font-weight-medium mb-3">Make: VF – 5YT – 1300 x 660 x 650mm

            </h6>
            </h4>
            <div class="row">
                <div class="col-lg-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
                    <div class="shadow mb-4">
                        <div class="position-relative">
                        <img class="img-fluid w-100" src="img/v7.jpg" alt="" style={{height:'250px'}}/>
                            <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                               
                            </a>
                        </div>
                       
                    </div>
                </div>
                <div class="col-lg-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
                    <div class="shadow mb-4">
                        <div class="position-relative">
                            <img class="img-fluid w-100" src="img/v8.jpg" alt="" style={{height:'250px'}}/>
                            <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                                
                            </a>
                        </div>
                      
                    </div>
                </div>
                <div class="col-lg-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
                    <div class="shadow mb-4">
                        <div class="position-relative">
                        <img class="img-fluid w-100" src="img/v9.jpg" alt="" style={{height:'250px'}}/>
                            <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                                
                            </a>
                        </div>
                       
                    </div>
                </div>
            </div>

           
        </div>
         <div class="container">
           
            <h4 class="text-secondary text-uppercase text-center font-weight-medium mb-3">4. ELMACO – Bench Grinder and 5. COMPRESSOR – 7.5 HP – 10kgs/〖𝒄𝒎〗^𝟐 pressure



            (Vertical Milling Machine)
       
            </h4>
            
<div class="row justify-content-center align-items-center">
                    <div class="col-lg-5 text-center" data-aos="fade-right" data-aos-duration="3000">
                        <img class="img-fluid" src="img/v10.jpg" alt="" style={{height:"350px",width:"800px"}} />
                    </div>
                    <div class="col-lg-5 text-center" data-aos="fade-left" data-aos-duration="3000">
                        <img class="img-fluid" src="img/v11.jpg" alt="" style={{height:"350px",width:"800px"}}  />
                    </div>
                </div>
           
        </div>

      
       <div class="container-fluid pt-5 pb-3">
    <div class="container">
       
        <h1 class="display-4 text-center mb-5">Measurement Tools Data</h1>
       
        <MeasurementToolsTable/>
    </div>
</div>
        
    </div> */}

<div>
            <div className="container-fluid pt-5 pb-3">
                <div className="container">
                    <h1 className="display-4 text-center mb-5">Design and Development</h1>
                    <h5 className="text-secondary text-center font-weight-medium mb-3" style={{ textAlign: 'justify' }}>
                        We specialize in offering comprehensive design and programming solutions for machining operations in VMC (Vertical Machining Center) machines, customized to meet each client's unique requirements. Our expert team ensures precision in every project, delivering high-quality, efficient machining processes. By understanding the specific needs of our customers, we provide tailored solutions that enhance performance and productivity. Whether it's a one-off job or ongoing support, our services are designed to deliver reliability and precision in every machining task.
                    </h5>
                    <div className="container-fluid py-5">
                        <div className="container pt-0 pt-lg-4">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-5 text-center" data-aos="fade-right" data-aos-duration="3000">
                                    <img className="img-fluid" src="img/d1.jpg" alt="" style={{ height: "350px", width: "800px" }} onClick={() => handleImageClick('img/d1.jpg')} />
                                </div>
                                <div className="col-lg-5 text-center" data-aos="fade-left" data-aos-duration="3000">
                                    <img className="img-fluid" src="img/d2.jpg" alt="" style={{ height: "350px", width: "800px" }} onClick={() => handleImageClick('img/d2.jpg')} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid mt-5 pb-2">
                <div className="container">
                    <h6 className="text-secondary text-uppercase text-center font-weight-medium mb-3">Our Machinery</h6>
                    <h1 className="display-4 text-center mb-5">Latest Machinery</h1>

                    <h4 className="text-secondary text-uppercase text-center font-weight-medium mb-3">
                        1. DMC 1035V ecoline – VMC Machine (Vertical Milling Machine)
                        <h6 className="text-secondary text-uppercase text-center font-weight-medium mb-3">Make: DMG MORI - 1100 x 600 x 600mm</h6>
                    </h4>
                    <div className="row">
                        <div className="col-lg-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
                            <div className="shadow mb-4">
                                <div className="position-relative">
                                    <img className="img-fluid w-100" src="img/v1.jpg" alt="" style={{ height: '250px' }} onClick={() => handleImageClick('img/v1.jpg')} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
                            <div className="shadow mb-4">
                                <div className="position-relative">
                                    <img className="img-fluid w-100" src="img/v2.jpg" alt="" style={{ height: '250px' }} onClick={() => handleImageClick('img/v2.jpg')} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
                            <div className="shadow mb-4">
                                <div className="position-relative">
                                    <img className="img-fluid w-100" src="img/v3.jpg" alt="" style={{ height: '250px' }} onClick={() => handleImageClick('img/v3.jpg')} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Repeat similar sections for other machines */}
                    <h4 className="text-secondary text-uppercase text-center font-weight-medium mb-3">
                        2. HAAS MACHINE – VMC MACHINE
                        <h6 className="text-secondary text-uppercase text-center font-weight-medium mb-3">Make: VF – 2YT – 760 x 510 x 510mm</h6>
                    </h4>
                    <div className="row">
                        <div className="col-lg-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
                            <div className="shadow mb-4">
                                <div className="position-relative">
                                    <img className="img-fluid w-100" src="img/v4.jpg" alt="" style={{ height: '250px' }} onClick={() => handleImageClick('img/v4.jpg')} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
                            <div className="shadow mb-4">
                                <div className="position-relative">
                                    <img className="img-fluid w-100" src="img/v5.jpg" alt="" style={{ height: '250px' }} onClick={() => handleImageClick('img/v5.jpg')} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
                            <div className="shadow mb-4">
                                <div className="position-relative">
                                    <img className="img-fluid w-100" src="img/v6.jpg" alt="" style={{ height: '250px' }} onClick={() => handleImageClick('img/v6.jpg')} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Additional sections can be added here similarly */}
                    <h4 className="text-secondary text-uppercase text-center font-weight-medium mb-3">
                        3. HAAS MACHINE – VMC MACHINE
                        <h6 className="text-secondary text-uppercase text-center font-weight-medium mb-3">Make: VF – 5YT – 1300 x 660 x 650mm</h6>
                    </h4>
                    <div className="row">
                        <div className="col-lg-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
                            <div className="shadow mb-4">
                                <div className="position-relative">
                                    <img className="img-fluid w-100" src="img/v7.jpg" alt="" style={{ height: '250px' }} onClick={() => handleImageClick('img/v7.jpg')} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
                            <div className="shadow mb-4">
                                <div className="position-relative">
                                    <img className="img-fluid w-100" src="img/v8.jpg" alt="" style={{ height: '250px' }} onClick={() => handleImageClick('img/v8.jpg')} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
                            <div className="shadow mb-4">
                                <div className="position-relative">
                                    <img className="img-fluid w-100" src="img/v9.jpg" alt="" style={{ height: '250px' }} onClick={() => handleImageClick('img/v9.jpg')} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <h4 className="text-secondary text-uppercase text-center font-weight-medium mb-3">
                        4. ELMACO – Bench Grinder and 5. COMPRESSOR – 7.5 HP – 10kgs/〖𝒄𝒎〗^𝟐 pressure
                    </h4>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-5 text-center" data-aos="fade-right" data-aos-duration="3000">
                            <img className="img-fluid" src="img/v10.jpg" alt="" style={{ height: "350px", width: "800px" }} onClick={() => handleImageClick('img/v10.jpg')} />
                        </div>
                        <div className="col-lg-5 text-center" data-aos="fade-left" data-aos-duration="3000">
                            <img className="img-fluid" src="img/v11.jpg" alt="" style={{ height: "350px", width: "800px" }} onClick={() => handleImageClick('img/v11.jpg')} />
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal for Enlarged Image */}
            {modalImage && (
                <div className="modal" onClick={closeModal}>
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <img className="modal-image" src={modalImage} alt="Enlarged view" />
                    </div>
                </div>
            )}
        </div>
    
        <div class="container-fluid pt-5 pb-3">
    <div class="container">
       
        <h1 class="display-4 text-center mb-5">Measurement Tools Data</h1>
       
        <MeasurementToolsTable/>
    </div>
</div>
    
    </div>
  )
}

export default Service