import React, { useEffect } from 'react'
import AOS from 'aos';  


const About = () => {
    const textStyle = {
        textAlign: 'justify',
        lineHeight: '1.6',
        marginBottom: '15px',
        fontFamily: 'Arial, sans-serif',
        fontSize: '16px',
        color: '#333',
      };
    
    useEffect(() => {
        AOS.init();
      }, []);
  return (
    <div>

<div class="page-header container-fluid bg-secondary pt-2 pt-lg-5 pb-2 mb-5">
        <div class="container py-5">
            <div class="row align-items-center py-4">
                <div class="col-md-6 text-center text-md-left">
                    <h1 class="mb-4 mb-md-0 text-white">About Us</h1>
                </div>
                <div class="col-md-6 text-center text-md-right">
                    <div class="d-inline-flex align-items-center">
                        <a class="btn text-white" href="/">Home</a>
                        <i class="fas fa-angle-right text-white"></i>
                        <a class="btn text-white disabled" href="">About Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
   


    
    <div class="container-fluid py-5">
        <div class="container">
            <div class="row align-items-center"  >
                <div class="col-lg-5"  data-aos="fade-right" data-aos-duration="3000">
                    <img class="img-fluid" src="img/abt.jpg" alt="" style={{height:"650px",width:"600px"}}/>
                </div>
                <div class="col-lg-7 mt-5 mt-lg-0 pl-lg-5" data-aos="fade-left" data-aos-duration="3000">
                    <h6 class="text-secondary text-uppercase font-weight-medium mb-3">Learn About Us</h6>
                    <h1 class="mb-4">Welcome to P&S Industries</h1>
                    <h5 class="font-weight-medium font-italic mb-4" style={{textAlign:'justify'}}>We Are Your Trusted Mechanical Solutions Provider.</h5>
                    <p style={textStyle}>
        P&S Industries is a proprietorship firm founded by Mrs. Nalina C. in 2007. Located at #C/90/A, KSSIDC Industrial Estate, Dyavasandra, Mahadevapura, Bengaluru-560048, Karnataka, India, we are proud to be an ISO 9001:2008 certified company. With years of industry experience, we pride ourselves on our ability to tackle any mechanical challenge. Our team of skilled professionals is dedicated to providing innovative solutions that meet your needs.
      </p>

      <p style={textStyle}>
        We wish to introduce ourselves as a professionally running Small Scale industry providing all machining jobs like Rough Milling, Surface Grinding, Jig Boring, Drilling, and Spotting. We are specialized in VMC (CNC) Precision Milling and Turning Jobs. We provide an environmentally friendly & healthy work atmosphere to continually improve the quality of our products and nurture genuine team spirit.
      </p>

      <p style={textStyle}>
        The firm has acquired an industrial plot measuring 2000 sq.mts with plot no. C/90/A. Our factory construction includes civil work such as Factory Shed, Side Cover, and Concrete Flooring.
      </p>
                    <div class="row">
                        <div class="col-sm-6 pt-3">
                            <div class="d-flex align-items-center">
                                <i class="fa fa-check text-primary mr-2"></i>
                                <p class="text-secondary font-weight-medium m-0">Comprehensive Mechanical Repairs</p>
                            </div>
                        </div>
                        <div class="col-sm-6 pt-3">
                            <div class="d-flex align-items-center">
                                <i class="fa fa-check text-primary mr-2"></i>
                                <p class="text-secondary font-weight-medium m-0">Fast and Efficient Service</p>
                            </div>
                        </div>
                        <div class="col-sm-6 pt-3">
                            <div class="d-flex align-items-center">
                                <i class="fa fa-check text-primary mr-2"></i>
                                <p class="text-secondary font-weight-medium m-0">Highly Skilled Technicians</p>
                            </div>
                        </div>
                        <div class="col-sm-6 pt-3">
                            <div class="d-flex align-items-center">
                                <i class="fa fa-check text-primary mr-2"></i>
                                <p class="text-secondary font-weight-medium m-0">100% Customer Satisfactione</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    


    <div class="container-fluid pt-5">
        <div class="container">
            <h6 class="text-secondary text-uppercase text-center font-weight-medium mb-3">Working Process</h6>
            <h1 class="display-4 text-center mb-5">How We Work</h1>
            <div class="row">
                <div class="col-lg-3 col-md-6"  data-aos="zoom-in"  data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center mb-5">
                        <div class="d-inline-flex align-items-center justify-content-center bg-white border border-light shadow rounded-circle mb-4"style={{width: "150px", height: "150px", borderWidth: "15px !important"}}>
                            <h2 class="display-2 text-secondary m-0">1</h2>
                        </div>
                        <h3 class="font-weight-bold m-0 mt-2">Order Placement</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6"  data-aos="zoom-in"  data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center mb-5">
                        <div class="d-inline-flex align-items-center justify-content-center bg-white border border-light shadow rounded-circle mb-4" style={{width: "150px", height: "150px", borderWidth: "15px !important"}}>
                            <h2 class="display-2 text-secondary m-0">2</h2>
                        </div>
                        <h3 class="font-weight-bold m-0 mt-2">Precision Machining</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6"  data-aos="zoom-in"  data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center mb-5">
                        <div class="d-inline-flex align-items-center justify-content-center bg-white border border-light shadow rounded-circle mb-4" style={{width: "150px", height: "150px", borderWidth: "15px !important"}}>
                            <h2 class="display-2 text-secondary m-0">3</h2>
                        </div>
                        <h3 class="font-weight-bold m-0 mt-2">Quality Assurance</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6"  data-aos="zoom-in"  data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center text-center mb-5">
                        <div class="d-inline-flex align-items-center justify-content-center bg-white border border-light shadow rounded-circle mb-4" style={{width: "150px", height: "150px", borderWidth: "15px !important"}}>
                            <h2 class="display-2 text-secondary m-0">4</h2>
                        </div>
                        <h3 class="font-weight-bold m-0 mt-2">Free Delivery</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    


    
    <div class="container-fluid py-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 m-0 my-lg-5 pt-0 pt-lg-5 pr-lg-5"  >
                    <h6 class="text-secondary text-uppercase font-weight-medium mb-3">Our Features</h6>
                    <h1 class="mb-4">Why Choose Us</h1>
                    <p style={textStyle}>
        <strong>Experienced Team: </strong>Our team comprises skilled professionals who bring years of experience in precision machining and manufacturing.
      </p>

      <p style={textStyle}>
        <strong>Advanced Technology: </strong>We utilize the latest in CNC technology, ensuring that we meet tight tolerances and produce high-precision components.
      </p>

      <p style={textStyle}>
        <strong>Quality Assurance: </strong>With our ISO 9001:2008 certification, we ensure that every process and product meets stringent quality standards.
      </p>
                       
                    <div class="row">
                        <div class="col-sm-6 mb-4">
                            <h1 class="text-secondary" data-toggle="counter-up">17</h1>
                            <h5 class="font-weight-bold">Years Expereince</h5>
                        </div>
                        <div class="col-sm-6 mb-4">
                            <h1 class="text-secondary" data-toggle="counter-up">150</h1>
                            <h5 class="font-weight-bold">Expert Worker</h5>
                        </div>
                        <div class="col-sm-6 mb-4">
                            <h1 class="text-secondary" data-toggle="counter-up">760</h1>
                            <h5 class="font-weight-bold">Happy Clients</h5>
                        </div>
                        <div class="col-sm-6 mb-4">
                            <h1 class="text-secondary" data-toggle="counter-up">1567</h1>
                            <h5 class="font-weight-bold">Jig Boring</h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5" data-aos="fade-down-left" data-aos-duration="3000">
                    <div class="d-flex flex-column align-items-center justify-content-center bg-secondary h-100 py-5 px-3">
                        <i class="fa fa-5x fa-certificate text-white mb-5"></i>
                        <h1 class="display-1 text-white mb-3">17+</h1>
                        <h1 class="text-white m-0">Years Experience</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid py-5">
        <div class="container">
            <div class="row">
            
            {/* <h1 class="display-4 text-center mb-5" style={{textAlign:'center',marginLeft:'100px'}}>  Company Organization Chart</h1> */}

            <div className="heading-container">
      <h1 className="heading">Company Organization Chart</h1>
      <style jsx>{`
        .heading-container {
          width: 100%;
          padding: 0 15px;
          box-sizing: border-box;
        }
        .heading {
          font-size: 2.5rem;
          text-align: center;
          margin-bottom: 3rem;
          color: #333;
          font-weight: 300;
        }
        @media (min-width: 768px) {
          .heading {
            font-size: 3.5rem;
            margin-left: 100px;
          }
        }
      `}</style>
    </div>
            <img class="img-fluid" src="img/tree.JPG" alt=""/>
                {/* <div class="col-lg-7 m-0 my-lg-5 pt-0 pt-lg-5 pr-lg-5">
                    <h6 class="text-secondary text-uppercase font-weight-medium mb-3">Our Features</h6>
                    <h1 class="mb-4">Why Choose Us</h1>
                    <p><strong>Experienced Team: </strong>Our team comprises skilled professionals who bring years of experience in precision machining and manufacturing.</p>
                    <p><strong>Advanced Technology:  </strong> We utilize the latest in CNC technology, ensuring that we meet tight tolerances and produce high-precision components.</p>
                    <p><strong>Quality Assurance: </strong> With our ISO 9001:2008 certification, we ensure that every process and product meets stringent quality standards.</p>
                       
                    <div class="row">
                        <div class="col-sm-6 mb-4">
                            <h1 class="text-secondary" data-toggle="counter-up">17</h1>
                            <h5 class="font-weight-bold">Years Expereince</h5>
                        </div>
                        <div class="col-sm-6 mb-4">
                            <h1 class="text-secondary" data-toggle="counter-up">150</h1>
                            <h5 class="font-weight-bold">Expert Worker</h5>
                        </div>
                        <div class="col-sm-6 mb-4">
                            <h1 class="text-secondary" data-toggle="counter-up">760</h1>
                            <h5 class="font-weight-bold">Happy Clients</h5>
                        </div>
                        <div class="col-sm-6 mb-4">
                            <h1 class="text-secondary" data-toggle="counter-up">1567</h1>
                            <h5 class="font-weight-bold">Jig Boring</h5>
                        </div>
                    </div>
                </div> */}
                
                    {/* <div class="d-flex flex-column align-items-center justify-content-center bg-secondary h-100 py-5 px-3">
                        <i class="fa fa-5x fa-certificate text-white mb-5"></i>
                        <h1 class="display-1 text-white mb-3">17+</h1>
                        <h1 class="text-white m-0">Years Experience</h1>
                    </div> */}
                </div>
            
        </div>
    </div>
   

    </div>
  )
}

export default About