// import React, { useEffect } from 'react'
// import AOS from 'aos'; 
// import 'aos/dist/aos.css';     

// function Gallery() {
//     useEffect(() => {
//         AOS.init();
//       }, []);
//   return (
//     <div>
//         <div class="page-header container-fluid bg-secondary pt-2 pt-lg-5 pb-2 mb-5">
//     <div class="container py-5">
//         <div class="row align-items-center py-4">
//             <div class="col-md-6 text-center text-md-left">
//                 <h1 class="mb-4 mb-md-0 text-white">Products</h1>
//             </div>
//             <div class="col-md-6 text-center text-md-right">
//                 <div class="d-inline-flex align-items-center">
//                     <a class="btn text-white" href="/">Home</a>
//                     <i class="fas fa-angle-right text-white"></i>
//                     <a class="btn text-white disabled" href="">Products</a>
//                 </div>
//             </div>
//         </div>
//     </div>
// </div> 

// <div class="container-fluid mt-5 pb-2" >
//         <div class="container">
//             <h6 class="text-secondary text-uppercase text-center font-weight-medium mb-3">Our Products</h6>
//             <h1 class="display-4 text-center mb-5">Latest Products</h1>
//             <div class="row">
//                 <div class="col-lg-4 mb-2" data-aos="fade-down-right"data-aos-duration="3000" >
//                     <div class="shadow mb-4"  data-aos="flip-left">
//                         <div class="position-relative">
//                         <img class="img-fluid w-100" src="img/img9.jpg" alt="" style={{height:'250px'}}/>
//                             <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                              
//                                 {/* <div class="d-flex text-light">
//                                     <small class="mr-2"><i class="fa fa-user text-secondary"></i> Admin</small>
//                                     <small class="mr-2"><i class="fa fa-folder text-secondary"></i> Web Design</small>
//                                     <small class="mr-2"><i class="fa fa-comments text-secondary"></i> 15</small>
//                                 </div> */}
//                             </a>
//                         </div>
//                         {/* <p class="m-0 p-4">Amet dolores labore magna et amet tempor dolor et dolor. Et sit ipsum et eos rebum labore ea labore sea. Et sed elitr labore sed labore. Lorem et lorem amet sed sed kasd ipsum rebum</p> */}
//                     </div>
//                 </div>
//                 <div class="col-lg-4 mb-2" data-aos="flip-left" data-aos-duration="3000">
//                     <div class="shadow mb-4">
//                         <div class="position-relative">
//                         <img class="img-fluid w-100" src="img/img7.jpg" alt="" style={{height:'250px'}}/>
//                             <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                               
//                                 {/* <div class="d-flex text-light">
//                                     <small class="mr-2"><i class="fa fa-user text-secondary"></i> Admin</small>
//                                     <small class="mr-2"><i class="fa fa-folder text-secondary"></i> Web Design</small>
//                                     <small class="mr-2"><i class="fa fa-comments text-secondary"></i> 15</small>
//                                 </div> */}
//                             </a>
//                         </div>
//                         {/* <p class="m-0 p-4">Amet dolores labore magna et amet tempor dolor et dolor. Et sit ipsum et eos rebum labore ea labore sea. Et sed elitr labore sed labore. Lorem et lorem amet sed sed kasd ipsum rebum</p> */}
//                     </div>
//                 </div>
//                 <div class="col-lg-4 mb-2" data-aos="fade-down-left"data-aos-duration="3000">
//                     <div class="shadow mb-4">
//                         <div class="position-relative">
//                         <img class="img-fluid w-100" src="img/img8.jpg" alt="" style={{height:'250px'}}/>
//                             <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                               
//                                 {/* <div class="d-flex text-light">
//                                     <small class="mr-2"><i class="fa fa-user text-secondary"></i> Admin</small>
//                                     <small class="mr-2"><i class="fa fa-folder text-secondary"></i> Web Design</small>
//                                     <small class="mr-2"><i class="fa fa-comments text-secondary"></i> 15</small>
//                                 </div> */}
//                             </a>
//                         </div>
                       
//                     </div>
//                 </div>
//             </div>
//         </div>

//         <div class="container">
            
//             <div class="row">
//                 <div class="col-lg-4 mb-2"data-aos="fade-down-right"data-aos-duration="3000">
//                     <div class="shadow mb-4">
//                         <div class="position-relative">
//                         <img class="img-fluid w-100" src="img/img6.jpg" alt="" style={{height:'250px'}}/>
//                             <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                                
//                                 {/* <div class="d-flex text-light">
//                                     <small class="mr-2"><i class="fa fa-user text-secondary"></i> Admin</small>
//                                     <small class="mr-2"><i class="fa fa-folder text-secondary"></i> Web Design</small>
//                                     <small class="mr-2"><i class="fa fa-comments text-secondary"></i> 15</small>
//                                 </div> */}
//                             </a>
//                         </div>
//                         {/* <p class="m-0 p-4">Amet dolores labore magna et amet tempor dolor et dolor. Et sit ipsum et eos rebum labore ea labore sea. Et sed elitr labore sed labore. Lorem et lorem amet sed sed kasd ipsum rebum</p> */}
//                     </div>
//                 </div>
//                 <div class="col-lg-4 mb-2" data-aos="flip-left" data-aos-duration="3000">
//                     <div class="shadow mb-4">
//                         <div class="position-relative">
//                         <img class="img-fluid w-100" src="img/img5.jpg" alt="" style={{height:'250px'}}/>
//                             <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                              
//                                 {/* <div class="d-flex text-light">
//                                     <small class="mr-2"><i class="fa fa-user text-secondary"></i> Admin</small>
//                                     <small class="mr-2"><i class="fa fa-folder text-secondary"></i> Web Design</small>
//                                     <small class="mr-2"><i class="fa fa-comments text-secondary"></i> 15</small>
//                                 </div> */}
//                             </a>
//                         </div>
//                         {/* <p class="m-0 p-4">Amet dolores labore magna et amet tempor dolor et dolor. Et sit ipsum et eos rebum labore ea labore sea. Et sed elitr labore sed labore. Lorem et lorem amet sed sed kasd ipsum rebum</p> */}
//                     </div>
//                 </div>
//                 <div class="col-lg-4 mb-2" data-aos="fade-down-left"data-aos-duration="3000">
//                     <div class="shadow mb-4">
//                         <div class="position-relative">
//                         <img class="img-fluid w-100" src="img/img4.jpg" alt="" style={{height:'250px'}}/>
//                             <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                             
//                                 {/* <div class="d-flex text-light">
//                                     <small class="mr-2"><i class="fa fa-user text-secondary"></i> Admin</small>
//                                     <small class="mr-2"><i class="fa fa-folder text-secondary"></i> Web Design</small>
//                                     <small class="mr-2"><i class="fa fa-comments text-secondary"></i> 15</small>
//                                 </div> */}
//                             </a>
//                         </div>
                       
//                     </div>
//                 </div>
//             </div>
//         </div>
//         <div class="container">
            
//             <div class="row">
//                 <div class="col-lg-4 mb-2" data-aos="fade-down-right"data-aos-duration="3000">
//                     <div class="shadow mb-4">
//                         <div class="position-relative">
//                         <img class="img-fluid w-100" src="img/img3.jpg" alt="" style={{height:'250px'}}/>
//                             <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                                
//                                 {/* <div class="d-flex text-light">
//                                     <small class="mr-2"><i class="fa fa-user text-secondary"></i> Admin</small>
//                                     <small class="mr-2"><i class="fa fa-folder text-secondary"></i> Web Design</small>
//                                     <small class="mr-2"><i class="fa fa-comments text-secondary"></i> 15</small>
//                                 </div> */}
//                             </a>
//                         </div>
//                         {/* <p class="m-0 p-4">Amet dolores labore magna et amet tempor dolor et dolor. Et sit ipsum et eos rebum labore ea labore sea. Et sed elitr labore sed labore. Lorem et lorem amet sed sed kasd ipsum rebum</p> */}
//                     </div>
//                 </div>
//                 <div class="col-lg-4 mb-2">
//                     <div class="shadow mb-4" data-aos="flip-left" data-aos-duration="3000">
//                         <div class="position-relative">
//                         <img class="img-fluid w-100" src="img/img2.jpg" alt="" style={{height:'250px'}}/>
//                             <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                                
//                                 {/* <div class="d-flex text-light">
//                                     <small class="mr-2"><i class="fa fa-user text-secondary"></i> Admin</small>
//                                     <small class="mr-2"><i class="fa fa-folder text-secondary"></i> Web Design</small>
//                                     <small class="mr-2"><i class="fa fa-comments text-secondary"></i> 15</small>
//                                 </div> */}
//                             </a>
//                         </div>
//                         {/* <p class="m-0 p-4">Amet dolores labore magna et amet tempor dolor et dolor. Et sit ipsum et eos rebum labore ea labore sea. Et sed elitr labore sed labore. Lorem et lorem amet sed sed kasd ipsum rebum</p> */}
//                     </div>
//                 </div>
//                 <div class="col-lg-4 mb-2" data-aos="fade-down-left"data-aos-duration="3000">
//                     <div class="shadow mb-4">
//                         <div class="position-relative">
//                         <img class="img-fluid w-100" src="img/img1.jpg" alt="" style={{height:'250px'}}/>
//                             <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                                
//                                 {/* <div class="d-flex text-light">
//                                     <small class="mr-2"><i class="fa fa-user text-secondary"></i> Admin</small>
//                                     <small class="mr-2"><i class="fa fa-folder text-secondary"></i> Web Design</small>
//                                     <small class="mr-2"><i class="fa fa-comments text-secondary"></i> 15</small>
//                                 </div> */}
//                             </a>
//                         </div>
                       
//                     </div>
//                 </div>
//             </div>
//         </div>
//         <div class="container">
            
//             <div class="row">
//                 <div class="col-lg-4 mb-2" data-aos="fade-down-right"data-aos-duration="3000">
//                     <div class="shadow mb-4">
//                         <div class="position-relative">
//                         <img class="img-fluid w-100" src="img/img03.jpg" alt="" style={{height:'250px'}}/>
//                             <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                                
                                
//                             </a>
//                         </div>
                        
//                     </div>
//                 </div>
//                 <div class="col-lg-4 mb-2">
//                     <div class="shadow mb-4" data-aos="flip-left" data-aos-duration="3000">
//                         <div class="position-relative">
//                         <img class="img-fluid w-100" src="img/img02.jpg" alt="" style={{height:'250px'}}/>
//                             <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                                
                               
//                             </a>
//                         </div>
                       
//                     </div>
//                 </div>
//                 <div class="col-lg-4 mb-2" data-aos="fade-down-left"data-aos-duration="3000">
//                     <div class="shadow mb-4">
//                         <div class="position-relative">
//                         <img class="img-fluid w-100" src="img/img01.jpg" alt="" style={{height:'250px'}}/>
//                             <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: "0", left: "0", background: "rgba(0, 0, 0, .4)"}}>
                                
                               
//                             </a>
//                         </div>
                       
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>

// </div>
// );
// };



// export default Gallery;

// import React, { useState, useEffect } from 'react';
// import AOS from 'aos';
// import 'aos/dist/aos.css';

// function Gallery() {
//   const [enlargedImageIndex, setEnlargedImageIndex] = useState(null);

//   useEffect(() => {
//     AOS.init();
//   }, []);

//   const toggleEnlarge = (index) => {
//     if (enlargedImageIndex === index) {
//       setEnlargedImageIndex(null);
//     } else {
//       setEnlargedImageIndex(index);
//     }
//   };

//   const images = [
//     { src: "img/img9.jpg", alt: "Image 9" },
//     { src: "img/img7.jpg", alt: "Image 7" },
//     { src: "img/img8.jpg", alt: "Image 8" },
//     { src: "img/img6.jpg", alt: "Image 6" },
//     { src: "img/img5.jpg", alt: "Image 5" },
//     { src: "img/img4.jpg", alt: "Image 4" },
//     { src: "img/img3.jpg", alt: "Image 3" },
//     { src: "img/img2.jpg", alt: "Image 2" },
//     { src: "img/img1.jpg", alt: "Image 1" },
//     { src: "img/img03.jpg", alt: "Image 03" },
//     { src: "img/img02.jpg", alt: "Image 02" },
//     { src: "img/img01.jpg", alt: "Image 01" },
//   ];

//   return (
//     <div>
//       {/* Header section (unchanged) */}
//       <div className="page-header container-fluid bg-secondary pt-2 pt-lg-5 pb-2 mb-5">
//         {/* ... (header content remains the same) ... */}
//       </div>

//       <div className="container-fluid mt-5 pb-2">
//         <div className="container">
//           <h6 className="text-secondary text-uppercase text-center font-weight-medium mb-3">Our Products</h6>
//           <h1 className="display-4 text-center mb-5">Latest Products</h1>
//           <div className="row">
//             {images.map((image, index) => (
//               <div 
//                 key={index} 
//                 className={`col-lg-4 mb-2 ${enlargedImageIndex === index ? 'col-lg-12' : ''}`} 
//                 data-aos="fade-up" 
//                 data-aos-duration="1000" 
//                 data-aos-delay={index * 100}
//               >
//                 <div 
//                   className={`shadow mb-4 transition-all duration-300 ${enlargedImageIndex === index ? 'cursor-zoom-out' : 'cursor-zoom-in'}`} 
//                   onClick={() => toggleEnlarge(index)}
//                 >
//                   <div className="position-relative">
//                     <img 
//                       className="img-fluid w-100" 
//                       src={image.src} 
//                       alt={image.alt} 
//                       style={{
//                         height: enlargedImageIndex === index ? 'auto' : '250px',
//                         objectFit: enlargedImageIndex === index ? 'contain' : 'cover',
//                         maxHeight: enlargedImageIndex === index ? '80vh' : 'none'
//                       }}
//                     />
//                     {enlargedImageIndex !== index && (
//                       <div 
//                         className="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center text-decoration-none p-4" 
//                         style={{top: 0, left: 0, background: "rgba(0, 0, 0, .4)"}}
//                       >
//                         {/* You can add overlay content here if needed */}
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Gallery;


import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Gallery() {
  const [enlargedImageIndex, setEnlargedImageIndex] = useState(null);

  useEffect(() => {
    AOS.init();
  }, []);

  const toggleEnlarge = (index) => {
    if (enlargedImageIndex === index) {
      setEnlargedImageIndex(null); // Close the enlarged image
    } else {
      setEnlargedImageIndex(index); // Enlarge the clicked image
    }
  };

  const closeEnlargedImage = () => {
    setEnlargedImageIndex(null); // Close when clicking outside the enlarged image
  };

  const images = [
    { src: "img/img9.jpg", alt: "Image 9" },
    { src: "img/img7.jpg", alt: "Image 7" },
    { src: "img/img8.jpg", alt: "Image 8" },
    { src: "img/img6.jpg", alt: "Image 6" },
    { src: "img/img5.jpg", alt: "Image 5" },
    { src: "img/img4.jpg", alt: "Image 4" },
    { src: "img/img3.jpg", alt: "Image 3" },
    { src: "img/img2.jpg", alt: "Image 2" },
    { src: "img/img1.jpg", alt: "Image 1" },
    { src: "img/img03.jpg", alt: "Image 03" },
    { src: "img/img02.jpg", alt: "Image 02" },
    { src: "img/img01.jpg", alt: "Image 01" },
  ];

  return (
    <div>
      {/* Header section */}
      <div className="page-header container-fluid bg-secondary pt-2 pt-lg-5 pb-2 mb-5">
        {/* ... (header content remains the same) ... */}
      </div>

      <div className="container-fluid mt-5 pb-2">
        <div className="container">
          <h6 className="text-secondary text-uppercase text-center font-weight-medium mb-3">Our Products</h6>
          <h1 className="display-4 text-center mb-5">Latest Products</h1>
          <div className="row">
            {images.map((image, index) => (
              <div
                key={index}
                className={`col-lg-4 mb-2`}
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay={index * 100}
                onClick={() => toggleEnlarge(index)} // Click to enlarge/shrink the image
                style={{
                  position: 'relative',
                  transition: 'opacity 0.3s ease-in-out',
                  opacity: enlargedImageIndex !== null && enlargedImageIndex !== index ? 0.2 : 1, // Dim non-selected images
                  zIndex: enlargedImageIndex !== null && enlargedImageIndex !== index ? 0 : 1, // Ensure non-selected images are behind
                  cursor: enlargedImageIndex === index ? 'zoom-out' : 'zoom-in',
                }}
              >
                <div className="shadow mb-4">
                  <img
                    className="img-fluid w-100"
                    src={image.src}
                    alt={image.alt}
                    style={{
                      height: '250px',
                      objectFit: 'cover',
                      maxHeight: '250px',
                    }}
                  />
                </div>
              </div>
            ))}

            {/* Enlarged image overlay */}
            {enlargedImageIndex !== null && (
              <div
                className="overlay"
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100vw',
                  height: '100vh',
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1000, // Ensure it's on top of everything
                }}
                onClick={closeEnlargedImage} // Close the overlay when clicking outside the image
              >
                <img
                  src={images[enlargedImageIndex].src}
                  alt={images[enlargedImageIndex].alt}
                  style={{
                    maxHeight: '80%',
                    maxWidth: '80%',
                    objectFit: 'contain',
                    cursor: 'pointer', // Close when the image itself is clicked
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)', // Add shadow to make the image stand out
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
