import React, { useState } from 'react';

const measurementToolsData = {
  "VERNIERS": [
    { type: 'Digital Vernier', brand: 'Mitutoyo', range: '0 – 200mm', accuracy: '0.01mm', quantity: '01 No.' },
    { type: 'Digital Vernier', brand: 'Forbes', range: '0 – 150mm', accuracy: '0.01mm', quantity: '02 NoS.' },
    { type: 'Analog Vernier', brand: 'Mitutoyo', range: '0 – 300mm', accuracy: '0.02mm', quantity: '01 No.' },
    { type: 'Digital Vernier', brand: 'N/A', range: '0 – 300mm', accuracy: '0.02mm', quantity: '01 No.' },
  ],
  "MICROMETERS": [
    { type: 'Analog Micrometer', brand: 'Mitutoyo', range: '0 – 25mm', accuracy: '0.01mm', quantity: '01 No.' },
    { type: 'Analog Micrometer', brand: 'Mitutoyo', range: '25 – 50mm', accuracy: '0.01mm', quantity: '01 No.' },
    { type: 'Analog Micrometer', brand: 'Mitutoyo', range: '50 – 75mm', accuracy: '0.01mm', quantity: '01 No.' },
    { type: 'Analog Micrometer', brand: 'Mitutoyo', range: '75 – 100mm', accuracy: '0.01mm', quantity: '01 No.' },
    { type: 'Analog Micrometer', brand: 'Mitutoyo', range: '100 – 125mm', accuracy: '0.01mm', quantity: '01 No.' },
    { type: 'Analog Micrometer', brand: 'Mitutoyo', range: '125 – 150mm', accuracy: '0.01mm', quantity: '01 No.' },
    { type: 'Analog Micrometer', brand: 'Mitutoyo', range: '150 – 175mm', accuracy: '0.1mm', quantity: '01 No.' },
  ],
  "HEIGHT GAUGES": [
    { type: 'Analog Height Gauge', brand: 'Mitutoyo', range: '0 - 600mm', accuracy: '0.02mm', quantity: '01 No.' },
    { type: 'Digital Height Gauge', brand: 'Mitutoyo', range: '0 - 600mm', accuracy: '0.02mm', quantity: '01 No.' },
  ],
  "SURFACE TABLES": [
    { type: 'Surface Table', brand: 'NTMT', range: '1000x 1000mm', accuracy: 'N/A', quantity: '01 No.' },
    { type: 'Surface Table', brand: 'Micro craft', range: '600x 600mm', accuracy: 'N/A', quantity: '01 No.' },
  ],
  "BORE GAUGES": [
    { type: 'Bore Gauge', brand: 'Ocean Premium', range: '18 – 35mm', accuracy: 'N/A', quantity: '01 No.' },
    { type: 'Bore Gauge', brand: 'Mitutoyo', range: '35- 50mm', accuracy: 'N/A', quantity: '01 No.' },
    { type: 'Bore Gauge', brand: 'Mitutoyo', range: '50 – 100mm', accuracy: 'N/A', quantity: '01 No.' },
    { type: 'Bore Gauge', brand: 'Mitutoyo', range: '150 – 215mm', accuracy: 'N/A', quantity: '01 No.' },
  ],
  "SLIP GAUGES": [
    { type: 'Slip Gauges', brand: 'HI – Mezar', range: '0.05 – 100mm', accuracy: 'N/A', quantity: '01 No.' },
  ],
  "PROBE": [
    { type: 'Edge Pointer', brand: 'Vertex', range: 'PMP20', accuracy: 'N/A', quantity: '01 No.' },
  ],
  "DIAL INDICATORS": [
    { type: 'Dial Indicator', brand: 'Mitutoyo', range: '0 – 0.01mm', accuracy: 'N/A', quantity: '04 Nos.' },
    { type: 'Dial Indicator', brand: 'Mitutoyo', range: '0 – 0.001mm', accuracy: 'N/A', quantity: '01 No.' },
  ],
  "PIN GAUGES": [
    { type: 'Pin Gauge', brand: 'Advance Measuring Systems', range: '2.05 to 7.00mm', accuracy: '005mm', quantity: '01 Set.' },
    { type: 'Pin Gauge', brand: 'Advance Measuring Systems', range: '0.50 to 2.00mm', accuracy: '005mm', quantity: '01 Set.' },
  ],
  "A FRAME": [
    { type: 'Fork lifts', brand: 'S & T', range: '1500 kg', accuracy: 'N/A', quantity: '01 No.' },
  ],
};
const MeasurementToolsTable = () => {
    const [selectedCategory, setSelectedCategory] = useState(Object.keys(measurementToolsData)[0]);
  
    return (
      <div className="container mx-auto p-4 flex flex-col items-center">
        <style jsx>{`
          .table-container {
            overflow-x: auto;
            width: 100%;
            max-width: 1300px;
          }
          table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
          }
          th, td {
            padding: 12px 15px;
            text-align: left;
            border: 1px solid #e2e8f0;
          }
          th {
            background-color: #ffaa17;
            color: white;
            font-weight: bold;
          }
          tr:nth-child(odd) {
            background-color: #f7fafc;
          }
          tr:nth-child(even) {
            background-color: #edf2f7;
          }
          tr:hover {
            background-color: #e2e8f0;
          }
          @media (max-width: 640px) {
            table, thead, tbody, th, td, tr {
              display: block;
            }
            thead tr {
              position: absolute;
              top: -9999px;
              left: -9999px;
            }
            tr {
              margin-bottom: 15px;
            }
            td {
              border: none;
              position: relative;
              padding-left: 50%;
            }
            td:before {
              position: absolute;
              top: 6px;
              left: 6px;
              width: 45%;
              padding-right: 10px;
              white-space: nowrap;
              content: attr(data-label);
              font-weight: bold;
            }
          }
        `}</style>
        <div className="mb-4 w-full max-w-3xl">
          <label htmlFor="category-select" className="mr-2 font-semibold">Select Category:</label>
          <select 
            id="category-select"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {Object.keys(measurementToolsData).map((category) => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Brand</th>
                <th>Range</th>
                <th>Accuracy</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {measurementToolsData[selectedCategory].map((item, index) => (
                <tr key={index}>
                  <td data-label="Type">{item.type}</td>
                  <td data-label="Brand">{item.brand}</td>
                  <td data-label="Range">{item.range}</td>
                  <td data-label="Accuracy">{item.accuracy}</td>
                  <td data-label="Quantity">{item.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  
  export default MeasurementToolsTable;
  