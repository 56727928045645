import React, { useState, useEffect } from 'react';
import './Testinomial.css';
  


const testimonials = [
  {
    id: 1,
  name: 'M/S. VIJAYALAKSHMI PATTERN WORKS',
  text: `TIER 2 SUPPLIER (for)
  1. M/S. HAL FOUNDARY & FORGE DIVISION
  2. M/S. GRINDWELL NORTON
  3. M/S. RIALTO ENTERPRISES PVT LTD
  
  Specialization:
  - Sealing & Cutting Dies
  - Thermo Forming Dies
  - Aluminum Pattern & Teak Wood Pattern`,
  image: 'img/testimonial-1.jpg'
  },
  {
    id: 2,
    name: 'M/S. SRI ENGINEERING',
    text: `TIER 2 SUPPLIER (for)
    1. M/S. IFB APL & IFB INDUSTRIES
    2. HYUNDAI, MARUTI, MAHENDRA
    
    Specialization:
    - Mold Base
    - Core / Cavity
    - Die Sets
    - Die & Punch`,
    image: 'img/testimonial-2.jpg'
  },
  {
    id: 3,
    name: 'M/S. SRI RAM INDUSTRIES',
    text: `TIER 2 SUPPLIER (for)
    1. M/S. IFB APL & IFB INDUSTRIES
    2. Autoliv
    
    Specialization:
    - Die Sets
    - Die & Punch`,
    image: 'img/testimonial-4.jpg'
  },
  {
    id: 4,
    name: ' M/S. MECH TECH',
    text: `TIER-1 SUPPLIER `,
   
    image: 'img/testimonial-1.jpg'
  },
  {
    id:5,
    name: 'M/S. ELECTROHMS', 
 
    text: `TIER-1 SUPPLIER `,
   
   
    image: 'img/testimonial-1.jpg'
  },
  {
    id: 6,
    name: 'M/S. A.O. SMITH',

    text: `TIER-1 SUPPLIER `,
   
   
    image: 'img/testimonial-1.jpg'
  },
  {
    id: 7,
    name: 'M/S. KWANGJIN INDIA AUTO PVT LTD',
    text: `TIER 2 SUPPLIER for Hyundai, KIA Motors
 `,
   
   
    image: 'img/testimonial-1.jpg'
  },
  {
    id: 8,
    name: 'M/S. STANZEN',

    text: `TIER 2 SUPPLIER for Autoliv
 `,
   
   
    image: 'img/testimonial-1.jpg'
  },




  // Add more testimonials as needed
];

const Testinomial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  useEffect(() => {
  
   
      
    const interval = setInterval(() => {
      nextTestimonial();
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="testimonial-container ">
      <div className="container py-5">
            <div className="d-flex flex-column mx-auto text-center mb-5 wow fadeInUp" data-wow-delay="0.2s" style={{maxWidth: "800px"}}>
               
                <h1 className="display-4 mb-4" style={{color: '#0F253B'}}>Our Clients </h1>
                <p className="mb-0" style={{color: '#0F253B'}}>At P & S Industries, we pride ourselves on delivering cutting-edge industrial solutions and unmatched service. Our clients trust us to provide the tools and expertise that drive their success. Here’s what they have to say about working with us.</p>
            </div>

      <div className="testimonial-slider" style={{ transform: `translateX(calc(-${currentIndex * 100}% - ${currentIndex * 3}rem))` }}>
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="testimonial-card">
            <img
              src={testimonial.image}
              alt={`Profile of ${testimonial.name}`}
              className="testimonial-image"
            />
            <p className="testimonial-text">{testimonial.text}</p>
            <h3 className="testimonial-name">{testimonial.name}</h3>
          </div>
        ))}
      </div>
      <div className="testimonial-background"></div>
      <div className="slider-controls">
        <button onClick={prevTestimonial}>&lt;</button>
        <button onClick={nextTestimonial}>&gt;</button>
      </div>
    </div>
    </div>
  );
};

export default Testinomial;