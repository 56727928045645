// import React from 'react'

// const Contact = () => {
//   return (
//     <div>
         
//     <div class="page-header container-fluid bg-secondary pt-2 pt-lg-5 pb-2 mb-5">
//         <div class="container py-5">
//             <div class="row align-items-center py-4">
//                 <div class="col-md-6 text-center text-md-left">
//                     <h1 class="mb-4 mb-md-0 text-white">Contact Us</h1>
//                 </div>
//                 <div class="col-md-6 text-center text-md-right">
//                     <div class="d-inline-flex align-items-center">
//                         <a class="btn text-white" href="/">Home</a>
//                         <i class="fas fa-angle-right text-white"></i>
//                         <a class="btn text-white disabled" href="">Contact Us</a>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>



    
//     <div class="container-fluid py-5">
//         <div class="container" style={{maxWidth: "900px"}}>
//             <div class="row">
//                 <div class="col-12">
//                     <div class="row">
//                         <div class="col-md-4">
//                             <div class="d-flex flex-column align-items-center justify-content-center text-center mb-5">
//                                 <div class="d-inline-flex align-items-center justify-content-center bg-white border border-light shadow rounded-circle mb-4" style={{width: "100px", height: "100px", borderWidth: "15px !important"}}>
//                                     <i class="fa fa-2x fa-map-marker-alt text-secondary"></i>
//                                 </div>
//                                 <h5 class="font-weight-medium m-0 mt-2">#B41/A,KSSIDC DIEA Mahadevapura,Bangalore-560048</h5>
//                             </div>
//                         </div>
//                         <div class="col-md-4">
//                             <div class="d-flex flex-column align-items-center justify-content-center text-center mb-5">
//                                 <div class="d-inline-flex align-items-center justify-content-center bg-white border border-light shadow rounded-circle mb-4" style={{width: "100px", height: "100px", borderWidth: "15px !important"}}>
//                                     <i class="fa fa-2x fa-envelope-open text-secondary"></i>
//                                 </div>
//                                 <h5 class="font-weight-medium m-0 mt-2">psin2010@gmail.com</h5>
//                             </div>
//                         </div>
//                         <div class="col-md-4">
//                             <div class="d-flex flex-column align-items-center justify-content-center text-center mb-5">
//                                 <div class="d-inline-flex align-items-center justify-content-center bg-white border border-light shadow rounded-circle mb-4" style={{width: "100px", height: "100px", borderWidth: "15px !important"}}>
//                                     <i class="fa fa-2x fa-phone-alt text-secondary"></i>
//                                 </div>
//                                 <h5 class="font-weight-medium m-0 mt-2">+91 9980893560</h5>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div class="col-12">
//                     <div class="contact-form">
//                         <div id="success"></div>
//                         <form name="sentMessage" id="contactForm" novalidate="novalidate">
//                             <div class="form-row">
//                                 <div class="col-md-6">
//                                     <div class="control-group">
//                                         <input type="text" class="form-control" id="name" placeholder="Your Name" required="required" data-validation-required-message="Please enter your name" />
//                                         <p class="help-block text-danger"></p>
//                                     </div>
//                                 </div>
//                                 <div class="col-md-6">
//                                     <div class="control-group">
//                                         <input type="email" class="form-control" id="email" placeholder="Your Email" required="required" data-validation-required-message="Please enter your email" />
//                                         <p class="help-block text-danger"></p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div class="form-row">
//                                 <div class="col-md-6">
//                                     <div class="control-group">
//                                         <input type="text" class="form-control" id="subject" placeholder="Subject" required="required" data-validation-required-message="Please enter a subject" />
//                                         <p class="help-block text-danger"></p>
//                                     </div>
//                                 </div>
//                                 <div class="col-md-6">
//                                     <div class="control-group">
//                                         <input type="number" class="form-control" id="Phone" placeholder="Phone" required="required" data-validation-required-message="Please enter your Number" />
//                                         <p class="help-block text-danger"></p>
//                                     </div>
//                                 </div>
//                             </div>
//                             {/* <div class="control-group">
//                                 <input type="text" class="form-control" id="subject" placeholder="Subject" required="required" data-validation-required-message="Please enter a subject" />
//                                 <p class="help-block text-danger"></p>
//                             </div> */}
//                             <div class="control-group">
//                                 <textarea class="form-control" rows="6" id="message" placeholder="Message" required="required" data-validation-required-message="Please enter your message"></textarea>
//                                 <p class="help-block text-danger"></p>
//                             </div>
//                             <div>
//                                 <button class="btn btn-primary py-3 px-5" type="submit" id="sendMessageButton">Send Message</button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
   
//     </div>
//   )
// }

// export default Contact


import React, { useState } from 'react';
import axios from 'axios';
import './Contact.css'; // Assuming you'll create a separate CSS file

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    phone: '',
    message: ''
  });
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('sending');

    try {
      const response = await axios.post('/send', formData);
      if (response.status === 200) {
        setSubmitStatus('success');
        setFormData({ name: '', email: '', subject: '', phone: '', message: '' });
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setSubmitStatus('error');
    }
  };

  return (
    <div className="contact-page">
        <div class="page-header container-fluid bg-secondary pt-2 pt-lg-5 pb-2 mb-5">
        <div class="container py-5">
            <div class="row align-items-center py-4">
                <div class="col-md-6 text-center text-md-left">
                    <h1 class="mb-4 mb-md-0 text-white">Contact Us</h1>
                </div>
                <div class="col-md-6 text-center text-md-right">
                    <div class="d-inline-flex align-items-center">
                        <a class="btn text-white" href="/">Home</a>
                        <i class="fas fa-angle-right text-white"></i>
                        <a class="btn text-white disabled" href="">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

      <div className="contact-info-section">
        <div className="container">
        <div class="row">
                        <div class="col-md-4">
                            <div class="d-flex flex-column align-items-center justify-content-center text-center mb-5">
                                <div class="d-inline-flex align-items-center justify-content-center bg-white border border-light shadow rounded-circle mb-4" style={{width: "100px", height: "100px", borderWidth: "15px !important"}}>
                                    <i class="fa fa-2x fa-map-marker-alt text-secondary"></i>
                                </div>
                                <h5 class="font-weight-medium m-0 mt-2">#C/90/A,KSSIDC DIEA Mahadevapura,Bangalore-560048</h5>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="d-flex flex-column align-items-center justify-content-center text-center mb-5">
                                <div class="d-inline-flex align-items-center justify-content-center bg-white border border-light shadow rounded-circle mb-4" style={{width: "100px", height: "100px", borderWidth: "15px !important"}}>
                                    <i class="fa fa-2x fa-envelope-open text-secondary"></i>
                                </div>
                                <h5 class="font-weight-medium m-0 mt-2">psin2010@gmail.com</h5>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="d-flex flex-column align-items-center justify-content-center text-center mb-5">
                                <div class="d-inline-flex align-items-center justify-content-center bg-white border border-light shadow rounded-circle mb-4" style={{width: "100px", height: "100px", borderWidth: "15px !important"}}>
                                    <i class="fa fa-2x fa-phone-alt text-secondary"></i>
                                </div>
                                <h5 class="font-weight-medium m-0 mt-2">+91 9945876320</h5>
                                <h5 class="font-weight-medium m-0 mt-2">+91 9886452359</h5>
                            </div>
                        </div>
                    </div>
        </div>
      </div>

      <div className="contact-form-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="contact-form">
                {submitStatus === 'success' && (
                  <div className="alert alert-success">Your message has been sent successfully!</div>
                )}
                {submitStatus === 'error' && (
                  <div className="alert alert-danger">An error occurred. Please try again later.</div>
                )}
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <div className="col-md-6">
                      <input 
                        type="text" 
                        className="form-control" 
                        id="name" 
                        placeholder="Your Name" 
                        required
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <input 
                        type="email" 
                        className="form-control" 
                        id="email" 
                        placeholder="Your Email" 
                        required
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6">
                      <input 
                        type="text" 
                        className="form-control" 
                        id="subject" 
                        placeholder="Subject" 
                        required
                        value={formData.subject}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <input 
                        type="tel" 
                        className="form-control" 
                        id="phone" 
                        placeholder="Phone" 
                        required
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea 
                      className="form-control" 
                      rows="6" 
                      id="message" 
                      placeholder="Message" 
                      required
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  
                  <button 
                    type="submit" 
                    className="btn btn-primary"
                    disabled={submitStatus === 'sending'}
                  >
                    {submitStatus === 'sending' ? 'Sending...' : 'Send Message'}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;