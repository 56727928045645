import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import Nav from './Components/Nav';
import Topheader from './Components/Topheader';

import Home from './Pages/Home';
import Footer from './Components/Footer';
import About from './Pages/About';
import Service from './Pages/Service';
import Slider from './Components/Slider';
import Contact from './Pages/Contact';
import Testinomial from './Pages/Testinomial';
import Gallery from './Pages/Gallery';
import MeasurementToolsTable from './Pages/MeasurementToolsTable';

function App() {
  return (
    
    <Router>
      <Topheader />
        <Nav />
        
      <div>
        

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About/>} />
          <Route path="/service" element={<Service/>} />
          <Route path='/slider' element={<Slider/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/testimonial' element={<Testinomial/>}/>
          <Route path='/gallery' element={<Gallery/>}/>
          <Route path='/measurement' element={<MeasurementToolsTable/>}/>
        </Routes>
      </div>
      <Footer/>
    </Router>
  );
}

export default App;
