// import React from 'react'

// const Nav = () => {
//   return (
//     <div>
//        <div class="container-fluid position-relative nav-bar p-0">
//         <div class="container-lg position-relative p-0 px-lg-3" style={{zIndex: "9"}}>
//             <nav class="navbar navbar-expand-lg bg-white navbar-light py-3 py-lg-0 pl-3 pl-lg-5">
//             <a href="" class="navbar-brand">
//     <h1 class="m-0 text-secondary" style={{ display: "flex", alignItems: "center", gap: "0" }}>
//         <img src="img/logops.png" alt="" style={{ height: "90px", width: "200px", margin: "0", padding: "0" }} />
//         <span class="text-primary" style={{ margin: "0", padding: "0" }}>P</span>S
//     </h1>
// </a>

//                 <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
//                     <span class="navbar-toggler-icon"></span>
//                 </button>
//                 <div class="collapse navbar-collapse justify-content-between px-3" id="navbarCollapse">
//                     <div class="navbar-nav ml-auto py-0">
//                         <a href="/" class="nav-item nav-link">Home</a>
//                         <a href="/about" class="nav-item nav-link active">About</a>
//                         {/* <a href="/Products" class="nav-item nav-link">Products</a> */}
//                         <a href="/service" class="nav-item nav-link">Services</a>
//                         <a href="/gallery" class="nav-item nav-link">Products</a>
//                         {/* <div class="nav-item dropdown">
//                             <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">Pages</a>
//                             <div class="dropdown-menu border-0 rounded-0 m-0">
//                                 <a href="blog.html" class="dropdown-item">Blog Grid</a>
//                                 <a href="single.html" class="dropdown-item">Blog Detail</a>
//                             </div>
//                         </div> */}
//                         <a href="/contact" class="nav-item nav-link">Contact</a>
//                     </div>
//                 </div>
//             </nav>
//         </div>
//     </div>
        
//     </div>
//   )
// }


// export default Nav


////////////////////////proper color//////////////////////////////////
// import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom'; // Make sure you have react-router-dom installed

// const Nav = () => {
//     const [activeLink, setActiveLink] = useState('/'); // Default active link

//     const handleLinkClick = (link) => {
//         setActiveLink(link); // Update active link
//     };

//     return (
//         <div>
//             <div className="container-fluid position-relative nav-bar p-0">
//                 <div className="container-lg position-relative p-0 px-lg-3" style={{ zIndex: "9" }}>
//                     <nav className="navbar navbar-expand-lg bg-white navbar-light py-3 py-lg-0 pl-3 pl-lg-5">
//                         <a href="/" className="navbar-brand">
//                             <h1 className="m-0 text-secondary" style={{ display: "flex", alignItems: "center", gap: "0" }}>
//                                 <img src="img/logops.png" alt="" style={{ height: "90px", width: "200px", margin: "0", padding: "0" }} />
//                                 <span className="text-primary" style={{ margin: "0", padding: "0" }}>P</span>S
//                             </h1>
//                         </a>

//                         <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
//                             <span className="navbar-toggler-icon"></span>
//                         </button>

//                         <div className="collapse navbar-collapse justify-content-between px-3" id="navbarCollapse">
//                             <div className="navbar-nav ml-auto py-0">
//                                 <NavLink to="/" 
//                                          className={`nav-item nav-link ${activeLink === '/' ? 'active' : ''}`} 
//                                          onClick={() => handleLinkClick('/')}>
//                                     Home
//                                 </NavLink>
//                                 <NavLink to="/about" 
//                                          className={`nav-item nav-link ${activeLink === '/about' ? 'active' : ''}`} 
//                                          onClick={() => handleLinkClick('/about')}>
//                                     About
//                                 </NavLink>
//                                 <NavLink to="/service" 
//                                          className={`nav-item nav-link ${activeLink === '/service' ? 'active' : ''}`} 
//                                          onClick={() => handleLinkClick('/service')}>
//                                     Services
//                                 </NavLink>
//                                 <NavLink to="/gallery" 
//                                          className={`nav-item nav-link ${activeLink === '/gallery' ? 'active' : ''}`} 
//                                          onClick={() => handleLinkClick('/gallery')}>
//                                     Products
//                                 </NavLink>
//                                 <NavLink to="/contact" 
//                                          className={`nav-item nav-link ${activeLink === '/contact' ? 'active' : ''}`} 
//                                          onClick={() => handleLinkClick('/contact')}>
//                                     Contact
//                                 </NavLink>
//                             </div>
//                         </div>
//                     </nav>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Nav;
// import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure react-router-dom is installed

// const Nav = () => {
//     const [activeLink, setActiveLink] = useState('/'); // Default active link

//     const handleLinkClick = (link) => {
//         setActiveLink(link); // Update active link
//     };

//     return (
//         <div>
//             <div className="container-fluid position-relative nav-bar p-0">
//                 <div className="container-lg position-relative p-0 px-lg-3" style={{ zIndex: "9" }}>
//                     <nav className="navbar navbar-expand-lg navbar-light bg-white py-3 py-lg-0">
//                         <a href="/" className="navbar-brand">
//                             <h1 className="m-0 text-secondary" style={{ display: "flex", alignItems: "center", gap: "0" }}>
//                                 <img src="img/logops.png" alt="" style={{ height: "90px", width: "200px", margin: "0", padding: "0" }} />
//                                 <span className="text-primary" style={{ margin: "0", padding: "0" }}>P</span>S
//                             </h1>
//                         </a>

//                         <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
//                             <span className="navbar-toggler-icon"></span>
//                         </button>

//                         <div className="collapse navbar-collapse" id="navbarCollapse">
//                             <div className="navbar-nav ml-auto py-0">
//                                 <NavLink to="/" 
//                                          className={`nav-item nav-link ${activeLink === '/' ? 'active' : ''}`} 
//                                          onClick={() => handleLinkClick('/')}>
//                                     Home
//                                 </NavLink>
//                                 <NavLink to="/about" 
//                                          className={`nav-item nav-link ${activeLink === '/about' ? 'active' : ''}`} 
//                                          onClick={() => handleLinkClick('/about')}>
//                                     About
//                                 </NavLink>
//                                 <NavLink to="/service" 
//                                          className={`nav-item nav-link ${activeLink === '/service' ? 'active' : ''}`} 
//                                          onClick={() => handleLinkClick('/service')}>
//                                     Services
//                                 </NavLink>
//                                 <NavLink to="/gallery" 
//                                          className={`nav-item nav-link ${activeLink === '/gallery' ? 'active' : ''}`} 
//                                          onClick={() => handleLinkClick('/gallery')}>
//                                     Products
//                                 </NavLink>
//                                 <NavLink to="/contact" 
//                                          className={`nav-item nav-link ${activeLink === '/contact' ? 'active' : ''}`} 
//                                          onClick={() => handleLinkClick('/contact')}>
//                                     Contact
//                                 </NavLink>
//                             </div>
//                         </div>
//                     </nav>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Nav;

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Nav = () => {
    const [activeLink, setActiveLink] = useState('/');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleLinkClick = (link) => {
        setActiveLink(link);
        setIsMenuOpen(false);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div>
            <div className="container-fluid position-relative nav-bar p-0">
                <div className="container-lg position-relative p-0 px-lg-3" style={{ zIndex: "9" }}>
                    <nav className="navbar navbar-expand-lg bg-white navbar-light py-3 py-lg-0 pl-3 pl-lg-5">
                        <a href="/" className="navbar-brand">
                        <h1 className="m-0 text-secondary" style={{ display: "flex", alignItems: "center", gap: "0px" }}>
    <img
        src="img/logo3.png"
        alt=""
        style={{
            height: "90px",
            width: "190px",
            margin: "0",
            padding: "0",
            marginLeft: '-40px'
        }}
    />
    <span className="text-primary" style={{ margin: "0", padding: "0", lineHeight: "1" }}>
        P&S&nbsp;
    </span>
    Industries
</h1>

                        </a>

                        <button 
                            type="button" 
                            className="navbar-toggler" 
                            onClick={toggleMenu}
                            aria-expanded={isMenuOpen}
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className={`collapse navbar-collapse justify-content-between px-3 ${isMenuOpen ? 'show' : ''}`} id="navbarCollapse">
                            <div className="navbar-nav ml-auto py-0">
                                <NavLink to="/"
                                    className={`nav-item nav-link ${activeLink === '/' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('/')}>
                                    Home
                                </NavLink>
                                <NavLink to="/about"
                                    className={`nav-item nav-link ${activeLink === '/about' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('/about')}>
                                    About
                                </NavLink>
                                <NavLink to="/service"
                                    className={`nav-item nav-link ${activeLink === '/service' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('/service')}>
                                    Services
                                </NavLink>
                                <NavLink to="/gallery"
                                    className={`nav-item nav-link ${activeLink === '/gallery' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('/gallery')}>
                                    Products
                                </NavLink>
                                <NavLink to="/contact"
                                    className={`nav-item nav-link ${activeLink === '/contact' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('/contact')}>
                                    Contact
                                </NavLink>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Nav;