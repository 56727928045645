// import React, { useState, useEffect } from 'react';
// // import img11 from './img/img11.png';
// // import img22 from './img/img22.png';
// import AOS from 'aos'; 
// import 'aos/dist/aos.css'; 


// const Slider = () => {
//   const [activeIndex, setActiveIndex] = useState(0);
//   const slides = [
//     {
//       image: "img/bg2.jpg",
//       title: "Best For Innovative Mechanical Solutions",
//       subtitle: "Mechanical Excellence in Every Cut "
//     },
//     {
//       image: "img/p11.png",
//       title: "Revolutionizing Machinery ",
//       subtitle: "Powerful Solutions for Mechanical "
//     },
//     {
//       image: "img/p22.png",
//       title: "Highly Professional Staff",
//       subtitle: "Precision Engineering"
//     }
//     // },
//     // {
//     //     image: "img/bg5.jpg",
//     //     title: "Highly Professional Staff",
//     //     subtitle: "Precision Engineering"
//     //   }
//   ];

//   useEffect(() => {
   
//         AOS.init();
      
//     const interval = setInterval(() => {
//       setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
//     }, 4000); // Change slide every 5 seconds

//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className="container-fluid p-0">
//       <div id="header-carousel" className="carousel slide" data-ride="carousel">
//         <div className="carousel-inner">
//           {slides.map((slide, index) => (
//             <div key={index} className={`carousel-item ${index === activeIndex ? 'active' : ''}`}>
//               <img className="w-100" src={slide.image} alt="Image" style={{height:"530px"}}/>
//               <div className="carousel-caption d-flex flex-column align-items-center justify-content-center" data-aos="fade-down-right"data-aos-duration="3000" >
//                 <div className="p-3" style={{maxWidth: "900px"}}>
//                   <h4 className="text-white text-uppercase mb-md-3" data-aos="fade-down-left"data-aos-duration="3000">{slide.subtitle}</h4>
//                   <h1 className="display-3 text-white mb-md-4" data-aos="fade-down-right"data-aos-duration="3000">{slide.title}</h1>
                  
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//         <a className="carousel-control-prev" href="#header-carousel" data-slide="prev">
//           <div className="btn btn-secondary" style={{width: "45px", height:"45px"}}>
//             <span className="carousel-control-prev-icon mb-n2"></span>
//           </div>
//         </a>
//         <a className="carousel-control-next" href="#header-carousel" data-slide="next">
//           <div className="btn btn-secondary" style={{width: "45px", height: "45px"}}>
//             <span className="carousel-control-next-icon mb-n2"></span>
//           </div>
//         </a>
//       </div>
//     </div>
//   );
// };

// export default Slider;








import React, { useState, useEffect } from 'react';
import AOS from 'aos'; 
import 'aos/dist/aos.css'; 

const Slider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const slides = [
    {
      image: "img/bg2.jpg",
      title: "Best For Innovative Mechanical Solutions",
      subtitle: "Mechanical Excellence in Every Cut "
    },
    {
      image: "img/p11.png",
      title: "Revolutionizing Machinery ",
      subtitle: "Powerful Solutions for Mechanical "
    },
    {
      image: "img/p22.png",
      title: "Highly Professional Staff",
      subtitle: "Precision Engineering"
    }
  ];

  useEffect(() => {
    AOS.init();
    
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 4000); // Change slide every 4 seconds

    return () => clearInterval(interval);
  }, []);

  const handlePrevClick = (e) => {
    e.preventDefault();
    setActiveIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  return (
    <div className="container-fluid p-0">
      <div id="header-carousel" className="carousel slide">
        <div className="carousel-inner">
          {slides.map((slide, index) => (
            <div key={index} className={`carousel-item ${index === activeIndex ? 'active' : ''}`}>
              <img className="w-100" src={slide.image} alt="Image" style={{ height: "530px" }} />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center" data-aos="fade-down-right" data-aos-duration="3000">
                <div className="p-3" style={{ maxWidth: "900px" }}>
                  <h4 className="text-white text-uppercase mb-md-3" data-aos="fade-down-left" data-aos-duration="3000">{slide.subtitle}</h4>
                  <h1 className="display-3 text-white mb-md-4" data-aos="fade-down-right" data-aos-duration="3000">{slide.title}</h1>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button className="carousel-control-prev" onClick={handlePrevClick} style={{backgroundColor:"transparent",borderColor:"transparent"}}>
          <div className="btn btn-secondary" style={{ width: "45px", height: "45px" }}>
            <span className="carousel-control-prev-icon mb-n2"></span>
          </div>
        </button>
        <button className="carousel-control-next" onClick={handleNextClick} style={{backgroundColor:"transparent",borderColor:"transparent"}}>
          <div className="btn btn-secondary" style={{ width: "45px", height: "45px" }}>
            <span className="carousel-control-next-icon mb-n2"></span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Slider;
